import { useCallback } from 'react'
import validator from 'validator'

const useBookingRecaption = ({
  setSearchEmail,
  searchEmail,
  form,
  setForm,
  contractDistribution,
  setResponseContractDistribution
}) => {
  const handelEmail = useCallback(
    event => {
      const { value } = event.target
      setSearchEmail({ search: value.toLowerCase() })
    },
    [searchEmail]
  )

  const submitNewContract = () => {
    const email = searchEmail.search
    const copyForm = JSON.parse(JSON.stringify(form))
    if (form.emails.includes(email)) {
      return
    }
    if (validator.isEmail(email)) {
      const newContractDistributionList = {
        data: [...contractDistribution.data, { email }],
        status: contractDistribution.status
      }

      copyForm.emails.push(email)
      setResponseContractDistribution(newContractDistributionList)
      setForm(copyForm)
      setSearchEmail({ search: '' })
    }
  }
  return { submitNewContract, handelEmail }
}

export default useBookingRecaption
