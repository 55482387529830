import { FormControlLabel, Grid, MenuItem, RadioGroup, Typography } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import { CheckboxEntities, FormControlLabelStyle } from '../../Entities.style'
import {
  RadioStyle,
  StyleSelect,
  TextFieldStyle,
  Typography5Style,
  Typography7Style
} from '../ClientMmDetails.style'
import { ReactComponent as CheckIcon } from '../../../../assets/Checkbox_checked.svg'
import useAxios from '../../../../hooks/useAxios'
import { routes } from '../../../../utils/constants'

const CounterpartySetting = ({ role, form, setForm, entity, setEntity, giveUp, setGiveUp }) => {
  const { response: makorPrimeBroker } = useAxios({ endPoint: routes.makorPrimeBroker })

  return (
    <Grid
      item
      xs={3.5}
      style={{
        overflowY: 'auto',
        overflowX: 'clip',
        maxHeight: '350px'
      }}
      className="container"
    >
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          flexFlow: 'wrap',
          alignItems: 'center',
          paddingTop: '8px',
          paddingLeft: '10px',
          marginTop: '12px'
        }}
      >
        <RadioGroup value={role} row>
          <Grid>
            <FormControlLabel
              value="Client"
              control={<RadioStyle />}
              label={
                <Typography
                  style={{
                    color: 'var(--Primary-text, #FFF)',
                    leadingTrim: 'both',
                    textEdge: 'cap',
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    marginRight: '32px',
                    fontWeight: '500',
                    lineHeight: '1'
                  }}
                >
                  Client
                </Typography>
              }
            />
          </Grid>
          <Grid>
            <FormControlLabel
              value="Market Maker"
              control={<RadioStyle />}
              label={
                <Typography
                  style={{
                    color: 'var(--Primary-text, #FFF)',
                    leadingTrim: 'both',
                    textEdge: 'cap',
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: '1'
                  }}
                >
                  Market Maker
                </Typography>
              }
            />
          </Grid>
        </RadioGroup>
      </Grid>

      <Grid item xs={12} style={{ display: 'flex', marginTop: '24px' }} className="primeBroker">
        <Typography5Style>Prime Broker / Mapping Value</Typography5Style>
      </Grid>

      <Grid
        container
        xs={12}
        className="container"
        style={{ display: 'flex', marginTop: '8px', gap: '16px' }}
      >
        <Grid item xs={4} style={{ lineHeight: 1, flex: 1, minWidth: '75px' }}>
          <StyleSelect
            value={form.makor_prime_broker}
            onChange={e =>
              setForm(prevForm => ({ ...prevForm, makor_prime_broker: e.target.value }))
            }
          >
            {makorPrimeBroker.status === 200 &&
              makorPrimeBroker.data.map(({ name }) => (
                <MenuItem
                  key={name}
                  value={name}
                  style={{ fontSize: '14px', paddingLeft: '8px', flexBasis: 'auto' }}
                >
                  {name}
                </MenuItem>
              ))}
          </StyleSelect>
        </Grid>
        <Grid item style={{ flex: 4, minWidth: '200px' }}>
          <TextFieldStyle
            value={form?.prime_broker_mapping}
            onChange={e =>
              setForm(prevForm => ({ ...prevForm, prime_broker_mapping: e.target.value }))
            }
            placeholder="Prime Broker Mapping Value"
          >
            {form?.prime_broker_mapping}
          </TextFieldStyle>
        </Grid>
      </Grid>

      <Grid item xs={12} style={{ marginTop: '24px' }}>
        <Typography5Style>Makor Entity</Typography5Style>
      </Grid>

      <Grid xs={12} style={{ display: 'flex', marginTop: '8px', lineHeight: 1 }}>
        <StyleSelect value={entity} onChange={e => setEntity(e.target.value)}>
          <MenuItem value="Makor Securities London" style={{ fontSize: '13px' }}>
            Makor Securities London
          </MenuItem>
          <MenuItem value="Oscar Gruss" style={{ fontSize: '13px' }}>
            Oscar Gruss
          </MenuItem>
        </StyleSelect>
      </Grid>

      <Grid item xs={12} style={{ marginTop: '24px' }}>
        <FormControlLabelStyle
          control={
            <CheckboxEntities
              checkedIcon={<CheckIcon />}
              checked={form?.is_structured}
              onChange={e =>
                setForm(prevForm => ({ ...prevForm, is_structured: e.target.checked }))
              }
            />
          }
          label={<Typography7Style>Structured</Typography7Style>}
        />
      </Grid>
      {/* 
      <Grid item xs={12} style={{ marginTop: '24px' }}>
        <FormControlLabelStyle
          control={<IOSSwitch checked={giveUp} onChange={e => setGiveUp(e.target.checked)} />}
          label={
            <Typography
              style={{
                color: 'var(--Primary-text, #FFF)',
                leadingTrim: 'both',
                textEdge: 'cap',
                fontFamily: 'Nunito',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: 'normal'
              }}
            >
              Give-up
            </Typography>
          }
        />
      </Grid> */}
    </Grid>
  )
}

export default CounterpartySetting

CounterpartySetting.propTypes = {
  role: PropTypes.string,
  form: PropTypes.object,
  setForm: PropTypes.func,
  entity: PropTypes.string,
  setEntity: PropTypes.func,
  giveUp: PropTypes.bool,
  setGiveUp: PropTypes.func
}
