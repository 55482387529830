import { Box, styled } from '@mui/system'
import { Grid, Typography, Checkbox, FormControlLabel } from '@mui/material'

export const TypographyStyle = styled(Typography)(() => {
  return {
    color: 'var(--blue, #4B9CFF)',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Nunito',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    textDecorationLine: 'underline',
    marginTop: '10px',
    cursor: 'pointer'
  }
})

export const TypographyEntityNameStyle = styled(Typography)(() => {
  return {
    color: 'var(--Light-grey-text, #BABEC7)',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Nunito',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    marginTop: '10px',
    marginLeft: '10px'
  }
})

export const RBSContainer = styled(Grid)(({ length, index, select }) => {
  let borderRadius = '0px'
  let borderBottom = '1px solid #39393C'
  let background = '#242428'
  if (Number(length) - 1 === Number(index)) {
    borderRadius = '0px 0px 4px 4px'
    borderBottom = '0px'
  }
  if (select === 'true') {
    background = '#39394E'
  }

  return {
    background,
    padding: '16px 24px',
    cursor: 'pointer',
    borderRadius,
    borderBottom,
    '&:hover': {
      background: '#3a3a3f'
    }
  }
})

export const HSBCContainer = styled(Grid)(({ length, index }) => {
  let borderRadius = '0px'
  let borderBottom = '1px solid #39393C'
  if (Number(length) - 1 === Number(index)) {
    borderRadius = '0px 0px 4px 4px'
    borderBottom = '0px'
  }

  return {
    background: '#242428',
    padding: '16px 24px',
    cursor: 'pointer',
    borderBottom,
    borderRadius,
    '&:hover': {
      background: '#3a3a3f'
    }
  }
})

export const TraderContainer = styled(Grid)(({ length, index }) => {
  let borderRadius = '0px'
  let borderBottom = '1px solid #39393C'
  if (Number(index) === 0) {
    borderRadius = '4px 4px 0px 0px'
  }
  if (Number(length) - 1 === Number(index)) {
    borderRadius = '0px 0px 4px 4px'
    borderBottom = '0px'
  }

  return {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '16px 24px',
    borderBottom,
    background: '#242428',
    borderRadius
  }
})

export const CheckboxEntities = styled(Checkbox)(({ theme, check }) => {
  let fill = 'transparent !important'
  if (check === 'true') {
    fill = '#000 !important'
  }
  return {
    '& .MuiSvgIcon-root': {
      height: '16px',
      width: '16px',
      color: theme.palette.separators.primary,
      backgroundColor: theme.palette.separators.primary,
      border: `${theme.palette.button.save.primary}`,
      borderRadius: '2px'
    },
    '&.Mui-checked .MuiSvgIcon-root': {
      height: '16px',
      width: '16px',
      color: `${theme.palette.button.save.primary} `,
      borderColor: `${theme.palette.button.save.primary}`,
      backgroundImage: `url()`,
      borderRadius: '2px'
    },
    '&.MuiButtonBase-root': {
      padding: '0px'
    },
    '&.Mui-disabled svg path': {
      fill
    }
  }
})

export const FormControlLabelStyle = styled(FormControlLabel)({
  marginLeft: '0px',
  marginRight: '0px'
})

export const TypographyCheckBoxForm = styled(Typography)({
  fontSize: '14px',
  lineHeight: 1,
  color: 'var(--Primary-text, #FFF)',
  fontWeight: '400',
  marginLeft: '8px'
})

export const EntitiesLinkContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  width: '100%',
  padding: '24px',
  backgroundColor: '#000',
  leadingTrim: 'both',
  textEdge: 'cap',
  height: '0px',
  fontFamily: 'Nunito',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  textDecorationStyle: 'solid',
  textDecorationSkipInk: 'none',
  textDecorationThickness: 'auto',
  textUnderlineOffset: 'auto',
  textUnderlinePosition: 'from-font',
  '& a': {
    color: '#7373BB'
  },
  '& a:nth-last-child(1)': {
    color: '#BABEC7',
    textDecoration: 'none'
  },
  '& p': {
    color: '#BABEC7',
    fontSize: '18px',
    textDecoration: 'none'
  }
})

export const EntitiesTool = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  backgroundColor: '#000',
  padding: '10px 8px'
}))
