/* eslint-disable */
import React, { useEffect } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import colorTheme from './utils/theme'
import Header from './components/Header/Header'
import Aside from './components/Aside/Aside'
import AppRouter from './components/AppRouter/AppRouter'
import ModalApp from './components/ModalApp/ModalApp'
import Notifications from './components/Notifications/Notifications'
import { useLocation } from 'react-router-dom'
import { LicenseManager } from 'ag-grid-enterprise'
import { automaticLogin, userLogout } from './redux/slices/Auth/AuthSlice'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom/dist'
import Footer from './components/Footer/Footer'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import markerSDK from '@marker.io/browser'
import { HeaderStyle } from './App.style'
import { webSocketWorkerEvent } from './services'

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE)

function App() {
  const location = useLocation().pathname
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  let widget = async () => {
    try {
      await markerSDK.loadWidget({
        project: '6734da8e3454675b4b309423'
      })
    } catch (e) {}
  }

  useEffect(() => {
    widget()
  }, [])

  useEffect(() => {
    const condition = [
      '/login',
      '/reset/password',
      '/change/password',
      '/new/password',
      '/sent/email'
    ].some(path => pathname.startsWith(path))
    if (!condition) {
      dispatch(automaticLogin(navigate, pathname))
    }
  }, [location])

  useEffect(() => {
    const handelMessage = message => {
      if (message.data.type === 'log-out-system') {
        dispatch(userLogout(navigate))
      }
    }
    webSocketWorkerEvent.addEventListener('message', handelMessage)
    return () => webSocketWorkerEvent.removeEventListener('message', handelMessage)
  }, [webSocketWorkerEvent])

  const theme = createTheme({
    palette: colorTheme
  })

  const renderMainContent = () => {
    const condition = [
      '/login',
      '/reset/password',
      '/change/password',
      '/new/password',
      '/sent/email'
    ].some(path => pathname.startsWith(path))

    if (condition) {
      return (
        <main
          style={{
            width: '100dvw',
            height: '100dvh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <AppRouter />
          <Notifications />
        </main>
      )
    } else {
      return (
        <>
          <Aside />
          <HeaderStyle>
            <Header />
          </HeaderStyle>
          <main
            style={{
              marginLeft: '4.1rem',
              height: '100%',
              maxHeight: '100%',
              width: 'auto'
            }}
          >
            <AppRouter />
            <ModalApp />
            <Notifications />
          </main>
          <footer>
            <Footer />
          </footer>
        </>
      )
    }
  }

  return <ThemeProvider theme={theme}>{renderMainContent()}</ThemeProvider>
}

export default App
