import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { setModal } from '../../../../redux/slices/Modal/modalSlice'
import axios from 'axios'
import Table from '../../../../components/Table/Table'
import { METHODS, MODAL_TITLE, MODAL_TYPE, routes } from '../../../../utils/constants'
import useAxios from '../../../../hooks/useAxios'
import { CheckIconStyle, EditIconStyle } from '../../../../components/styled/global.style'
import Loader from '../../../../components/Loader/Loader'
import { handelNotifications } from '../../../../redux/slices/Notification/notificationSlice'
import { Box, Typography } from '@mui/material'
import FlagCurrency from '../../../../components/FlagCurrency/FlagCurrency'
import CurrencyLogo from '../../../../components/CurrencyLogo/CurrencyLogo'

const CurrencyPairs = ({ params }) => {
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)

  const request = { endPoint: routes.currencyPair, params, modalType: MODAL_TYPE.CURRENCY_PAIRS }
  const { response, loading } = useAxios(request)
  const getCurrencyPairById = async id => {
    const url = process.env.REACT_APP_SERVER_URL.concat(routes.currencyPair, '/', id)

    try {
      const result = await axios.get(url, { headers: { Authorization: token } })
      dispatch(
        setModal({
          type: MODAL_TYPE.CURRENCY_PAIRS,
          title: MODAL_TITLE.EDIT_CURRENCY_PAIR,
          data: result.data,
          method: METHODS.PUT,
          isOpen: true
        })
      )
    } catch (error) {
      dispatch(
        handelNotifications({
          id: Date.now(),
          type: 'error',
          message: 'Sorry Something Went Wrong'
        })
      )
    }
  }

  const EditCurrencyPair = prop => {
    return <EditIconStyle onClick={() => getCurrencyPairById(prop.data.id)} />
  }

  const NdfEdit = prop => {
    return <CheckIconStyle isactive={prop.data.ndf} />
  }

  const CurrencyPairCell = prop => {
    return (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '8px',
          width: '100%',
          height: '100%'
        }}
      >
        <Typography>{prop.data.base_currency}</Typography>
        <CurrencyLogo currencyCode={prop.data.base_currency} />
        <FlagCurrency countryCode={prop.data.base_currency_country} />
        <Typography>/</Typography>
        <Typography>{prop.data.counter_currency}</Typography>
        <CurrencyLogo currencyCode={prop.data.counter_currency} />
        <FlagCurrency countryCode={prop.data.counter_currency_country} />
      </Box>
    )
  }

  const columns = [
    {
      headerName: 'Currency Pair',
      cellRenderer: CurrencyPairCell
    },
    {
      headerName: 'Ndf',
      field: 'ndf',
      cellRenderer: NdfEdit
    },
    {
      flex: 1,
      resizable: false,
      suppressMovable: true,
      cellClass: 'suppress-movable-col'
    },
    { headerName: 'Edit', cellRenderer: EditCurrencyPair }
  ]
  if (loading === true) {
    return <Loader />
  }

  if (response.status === 200) {
    return <Table rows={response.data} columns={columns} />
  }
}

CurrencyPairs.propTypes = { params: PropTypes.object, setParams: PropTypes.func }

export default CurrencyPairs
