import { styled, Typography } from '@mui/material'

export const BookingRecaptionSearchTitle = styled(Typography)(() => {
  return {
    color: 'var(--Secondary-text, #707079)',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Nunito',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal'
  }
})
