import { Checkbox, Grid, styled } from '@mui/material'

export const EntitiesLeftContainer = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px'
})

export const CheckboxStyle = styled(Checkbox)(({ theme }) => {
  return {
    margin: 0,
    marginRight: '8px',
    padding: 0,
    '& .MuiSvgIcon-root': {
      color: theme.palette.separators.primary,
      backgroundColor: theme.palette.separators.primary,
      border: `${theme.palette.button.save.primary}`,
      borderRadius: '2px'
    },
    '&.Mui-checked .MuiSvgIcon-root': {
      color: `${theme.palette.button.save.primary} `,
      borderColor: `${theme.palette.button.save.primary}`,
      borderRadius: '2px'
    },
    '&.Mui-disabled .MuiSvgIcon-root path': {
      color: 'black',
      backgroundColor: 'black',
      borderColor: `${theme.palette.button.save.primary}`,
      borderRadius: '2px',
      fill: 'black !important'
    }
  }
})
