import { Grid, Typography } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import { CheckboxEntities, FormControlLabelStyle, TypographyCheckBoxForm } from '../Entities.style'
import FlagCurrency from '../../../components/FlagCurrency/FlagCurrency'
import { ReactComponent as CheckIcon } from '../../../assets/Checkbox_checked.svg'
import TradingAccount from './components/TradingAccount'
import Traders from './components/Traders'
import { EntitySideContainer } from './EntitesSide.style'

const EntitiesSide = ({
  entityPrimeBroker,
  entityName,
  entityBseDetails,
  traders,
  clientMmId,
  userType,
  pba,
  swapDealer,
  handleClientClicked
}) => {
  return (
    <EntitySideContainer item xl={3} lg={4} md={5} sm={6}>
      <Grid>
        <Grid>
          <Typography style={{ color: 'white', fontSize: '24px', lineHeight: '1.25' }}>
            {entityName}
          </Typography>
        </Grid>

        <Grid style={{ display: 'flex', alignItems: 'center', marginTop: '20px', gap: '8px' }}>
          <FlagCurrency countryCode={entityBseDetails[0]?.countryCode} />

          <Typography
            style={{
              fontSize: '16px',
              color: '#BABEC7',
              maxWidth: '100%',
              lineHeight: '1'
            }}
          >
            {entityBseDetails[0]?.lei}
          </Typography>
        </Grid>

        <Grid
          style={{
            display: 'flex',
            marginTop: '24px',
            flexDirection: 'row',
            gap: '24px'
          }}
        >
          <FormControlLabelStyle
            name="swap_dealer"
            disabled
            checked={!!swapDealer}
            control={<CheckboxEntities check={String(!!swapDealer)} checkedIcon={<CheckIcon />} />}
            label={<TypographyCheckBoxForm>Swap Dealer</TypographyCheckBoxForm>}
            labelPlacement="end"
          />
          <FormControlLabelStyle
            name="pba"
            disabled
            checked={!!pba}
            control={<CheckboxEntities check={String(!!pba)} checkedIcon={<CheckIcon />} />}
            label={<TypographyCheckBoxForm>PBA</TypographyCheckBoxForm>}
            labelPlacement="end"
          />
        </Grid>
      </Grid>
      <Grid>
        <Grid style={{ margin: '32px 0px' }}>
          <TradingAccount
            entityPrimeBroker={entityPrimeBroker}
            entityName={entityName}
            handleClientClicked={handleClientClicked}
            clientMmId={clientMmId}
            userType={userType}
          />
          <Traders traders={traders} entityName={entityName} />
        </Grid>
      </Grid>
    </EntitySideContainer>
  )
}

export default EntitiesSide

EntitiesSide.propTypes = {
  entityPrimeBroker: PropTypes.object,
  traders: PropTypes.array,
  entityBseDetails: PropTypes.array,
  entityName: PropTypes.string,
  clientMmId: PropTypes.number,
  handleClientClicked: PropTypes.func,
  handelChangeEntityForm: PropTypes.func,
  userType: PropTypes.string,
  pba: PropTypes.number,
  swapDealer: PropTypes.number,
  form: PropTypes.object
}
