/* eslint-disable */
import { Grid } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import ClientMmChartDetails from '../ClientMmChartDetails/ClientMmChartDetails.js'
import ClientMmDetails from '../ClientMmDetails/ClientMmDetails.js'
import { MODAL_TYPE, routes } from '../../../utils/constants'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import EntitiesSide from '../EntitiesSide/EntitiesSide.js'
import EntityLinkNav from '../EntityLinkNav/EntityLinkNav.js'
import { ReactComponent as NextLink } from '../../../assets/next-arrow.svg'
import { getEntityById } from '../../../redux/slices/Entity/entitySlice.js'
import Loader from '../../../components/Loader/Loader.js'

function EntityDetails() {
  const params = useParams()
  const { entityId, counterpartyType, tradingAccountId } = params
  const token = useSelector(state => state.auth.token)
  const [entityPrimeBroker, setEntityPrimeBroker] = useState({ data: {}, status: '' })
  const [entityBaseDetails, setEntityBaseDetails] = useState([])
  const [traders, setTraders] = useState({ data: [], status: '' })
  const modal = useSelector(state => state.modal)
  const [clientName, setClientName] = useState('')
  const navigate = useNavigate()
  const [clientMmId, setClientMmId] = useState('')
  const [showEntityDetails, setShowEntityDetails] = useState(true)
  const [userType, setUserType] = useState('')

  const dispatch = useDispatch()
  const { loader, data } = useSelector(state => state.entity)

  useEffect(() => {
    dispatch(getEntityById(entityId, navigate))
    if (tradingAccountId && clientName === '') {
    }
  }, [])

  useEffect(() => {
    if (!isNaN(tradingAccountId) && ['client', 'market maker'].includes(counterpartyType)) {
      setShowEntityDetails(false)
      setUserType(counterpartyType)
      setClientMmId(tradingAccountId)
    }
    getEntitiesDetails()
    getTraderOfEntity()
  }, [])

  useEffect(() => {
    if (modal.type == MODAL_TYPE.TRADING_ACCOUNT && modal.getRequest === true) {
      getEntitiesDetails()
    }
    if (modal.type === MODAL_TYPE.TRADER && modal.getRequest === true) {
      getTraderOfEntity()
    }
  }, [modal])

  if (loader === true) {
    return <Loader />
  }
  const { entity_name: entityName, pba, swap_dealer } = data

  const getEntitiesDetails = async () => {
    const url = process.env.REACT_APP_SERVER_URL.concat(routes.entitiesPrimeBroker, '/', entityId)
    try {
      let element
      const result = await axios.get(url, { headers: { Authorization: token } })
      const { baseDetails, primeBrokers } = result.data
      delete result.data.baseDetails
      if (tradingAccountId) {
        for (const primeBroker of primeBrokers) {
          if (typeof Number(tradingAccountId) === 'number' && result.data[primeBroker].length) {
            element = result.data[primeBroker].find(
              rbs => Number(rbs.id) === Number(tradingAccountId)
            )
            if (element) {
              break
            }
          }
        }
        if (!element) {
          navigate('/entities')
        }
      }
      setEntityPrimeBroker({ data: result.data, status: result.status })
      setEntityBaseDetails(baseDetails)
    } catch (error) {
      navigate('/entities')
    }
  }

  const getTraderOfEntity = async () => {
    const url = process.env.REACT_APP_SERVER_URL.concat(routes.trader, '/entity/', entityId)
    try {
      const result = await axios.get(url, { headers: { Authorization: token } })
      setTraders({ data: result.data, status: result.status })
    } catch (error) {
      navigate('/entities')
    }
  }

  const handleClientClicked = (clientName, id, userType) => {
    setClientName(clientName)
    setShowEntityDetails(false)
    setUserType(userType)
    setClientMmId(id)
    navigate(`/entities/${entityId}/${userType}/${id}`)
  }

  const handelEntityLink = () => {
    setClientName('')
    setClientMmId('')
    setUserType('')
    setShowEntityDetails(true)
  }

  const entities = [
    { Entities: <Link to="/entities">Entities</Link>, text: 'Entities', nextLink: <NextLink /> },
    {
      [entityId]: (
        <Link to={`/entities/${entityId}`} onClick={handelEntityLink}>
          {entityName}
        </Link>
      ),
      text: entityName,
      nextLink: <NextLink />
    },
    {
      [clientName]: (
        <Link to={`/entities/${entityId}/${counterpartyType}/${tradingAccountId}`}>
          {clientName}
        </Link>
      ),
      text: clientName,
      nextLink: <NextLink />
    }
  ].filter(value => !['undefined', ''].includes(Object.keys(value)[0]))

  entities.at(-1).nextLink = <></>

  return (
    <Grid
      container
      style={{
        height: '100%',
        background: '#171719'
      }}
    >
      <EntityLinkNav entities={entities}></EntityLinkNav>
      <EntitiesSide
        entityPrimeBroker={entityPrimeBroker}
        traders={traders}
        handleClientClicked={handleClientClicked}
        entityName={entityName}
        pba={pba}
        swapDealer={swap_dealer}
        userType={userType}
        entityBseDetails={entityBaseDetails}
        clientMmId={clientMmId}
      />
      <Grid item xl={9} lg={8} md={7} sm={12} style={{ padding: '24px' }}>
        {showEntityDetails ? (
          <ClientMmChartDetails />
        ) : (
          <ClientMmDetails
            clientMmId={clientMmId}
            userType={userType}
            getEntitiesDetails={getEntitiesDetails}
          />
        )}
      </Grid>
    </Grid>
  )
}
EntityDetails.propTypes = { params: PropTypes.object, entityName: PropTypes.string }

export default EntityDetails
