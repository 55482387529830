import {
  ACTION,
  DIRECTION,
  METHODS,
  MODAL_TITLE,
  MODAL_TYPE,
  routes
} from '../../../utils/constants'
import { webSocketWorkerEvent } from '../../../services'
import { useDispatch, useSelector } from 'react-redux'
import { setModal } from '../../../redux/slices/Modal/modalSlice'
import axios from 'axios'
import { cleanEmptyField } from '../../../helper'

const useToolBarSetting = ({
  dealsGroup,
  tradesGroup,
  dealTradesAllocateGroup,
  dealsGiveUpGroup,
  dealTradesGroup,
  dispatchNotification
}) => {
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const handelGroupButtonRender = () => {
    return (
      Object.keys(dealsGroup).length === 0 ||
      (Object.keys(dealsGroup).length === 1 && Object.keys(tradesGroup).length >= 1)
    )
  }
  const handelAllocateTrade = () => {
    const data = Object.values(dealTradesAllocateGroup)[0]
    dispatch(
      setModal({
        isOpen: true,
        title: MODAL_TITLE.ALLOCATE_BLOCK,
        type: MODAL_TYPE.ALLOCATE_BLOCK,
        method: METHODS.PUT,
        data
      })
    )
  }
  const handelDealGiveUp = () => {
    const deals_id = Object.keys(dealsGiveUpGroup)
    webSocketWorkerEvent.sendEvent({
      type: 'give_up_deals',
      data: {
        deals_id
      }
    })
  }

  const handelTradeDealDelete = () => {
    const deals_id = Object.keys(dealsGroup)
    const trades_id = Object.keys(tradesGroup)

    const data = {
      trades_id: trades_id,
      deals_id
    }

    cleanEmptyField(data)

    webSocketWorkerEvent.sendEvent({
      type: 'delete_trades_or_deals',
      data: data
    })
  }

  const handelGroupTrade = () => {
    let trades, deal
    const ERROR = 'error'

    trades = Object.values(tradesGroup)
    const symbols = Array.from(new Set(trades.map(({ symbol }) => symbol)))
    deal = Object.values(dealsGroup)[0]
    if (deal) {
      trades = trades.concat(deal.trades)
    }
    if (deal && deal.symbol && !symbols.includes(deal.symbol)) {
      dispatchNotification(`Can't Group Trades To A Deal If Not Same Symbol`, ERROR)
    }
    if (trades.length > 1) {
      if (symbols.length !== 1) {
        dispatchNotification(`Can't Group Trades If Not Same Symbol`, ERROR)
        return
      }
      const directions = trades.map(({ direction }) => direction)
      const removeDuplicateDirections = new Set(directions)

      if (removeDuplicateDirections.size === 1) {
        const tradesId = Object.keys(tradesGroup).map(tradeId => +tradeId)
        webSocketWorkerEvent.sendEvent({
          type: 'group_trades',
          data: {
            trades_id: tradesId,
            deal_id: deal?.id
          }
        })
        return
      }

      const sides = trades.map(({ side }) => side)
      const removeDuplicateSides = new Set(sides)
      if (removeDuplicateSides.size === 1) {
        dispatchNotification(`Can't Group Trades If Sides Are The Same`, ERROR)
        return
      }
      const directionsClient = trades
        .filter(({ direction }) => direction === DIRECTION.CLIENT)
        .map(({ side }) => side)
      const removeDuplicateDirectionsClient = new Set(directionsClient)
      if (removeDuplicateDirectionsClient.size !== 1) {
        dispatchNotification(`Can't Group Trades If Have Different Client Direction Side`, ERROR)
        return
      }
      const directionsMarketMaker = trades
        .filter(({ direction }) => direction === DIRECTION.MARKET_MAKER)
        .map(({ side }) => side)
      const removeDuplicateDirectionsMarketMaker = new Set(directionsMarketMaker)
      if (removeDuplicateDirectionsMarketMaker.size !== 1) {
        dispatchNotification(
          `Can't Group Trades If Have Different Market Maker Direction Side`,
          ERROR
        )
        return
      }
    }
    if (deal && deal.symbol) {
      if (!symbols.includes(deal.symbol)) {
        dispatchNotification(`Can't Group Trades To A Deal If Not Same Symbol`, ERROR)
        return
      }
    }

    const tradesId = Object.keys(tradesGroup).map(tradeId => +tradeId)
    webSocketWorkerEvent.sendEvent({
      type: 'group_trades',
      data: {
        trades_id: tradesId,
        deal_id: deal?.id
      }
    })
  }

  const handelAmendTradeLogic = async (id, body, disabled) => {
    const { premium_currency, option_payment_currency, type, payout_currency } = body
    const currencies = [premium_currency, option_payment_currency, payout_currency]

    try {
      const fetchCurrencyData = async currency => {
        if (!currency) return null
        const url = `${process.env.REACT_APP_SERVER_URL}${routes.currency}/${currency}`
        const response = await axios.get(url, { headers: { Authorization: token } })
        return response.data
      }

      const [premiumData, optionData, payoutCurrencyData] = await Promise.all(
        currencies.map(fetchCurrencyData)
      )

      const fetchTradeTypeData = async tradeType => {
        if (!tradeType) return null
        const url = `${process.env.REACT_APP_SERVER_URL}${routes.tradeType}/${tradeType}`
        const response = await axios.get(url, { headers: { Authorization: token } })
        return response.data
      }

      const typeInfo = await fetchTradeTypeData(body.type)

      const data = {
        ...body,
        trade_type: type.includes('Client') ? 'Client' : 'Market',
        premium_currency: premiumData,
        currency_pair: body.base_currency.concat(body.counter_currency),
        payout_currency: payoutCurrencyData,
        option_payment_currency: optionData,
        type: typeInfo,
        id
      }

      dispatch(
        setModal({
          type: MODAL_TYPE.TRADE,
          title: MODAL_TITLE.EDIT_TRADE,
          action: ACTION.UPDATE,
          disabled: disabled,
          data,
          method: METHODS.PUT,
          isOpen: true
        })
      )
    } catch (error) {
      console.log(error)
    }
  }

  const handelUnGroupDeals = async () => {
    const deals_id = Object.keys(dealsGroup).map(deal => Number(deal))
    webSocketWorkerEvent.sendEvent({
      type: 'un_group_deals',
      data: {
        deals_id
      }
    })
  }

  const handelAmendTrade = async () => {
    const trade = Object.entries(tradesGroup)[0]
    const dealTrade = Object.entries(dealTradesGroup)[0]
    if (trade) {
      const [tradeId, tradeBody] = trade
      handelAmendTradeLogic(tradeId, tradeBody, false)
    } else if (dealTrade) {
      const [tradeId, tradeBody] = dealTrade
      handelAmendTradeLogic(tradeId, tradeBody, true)
    }
  }

  const disabledGroupTrade = () => {
    if (
      Object.keys(tradesGroup).length >= 2 ||
      (Object.keys(dealsGroup).length === 1 && Object.keys(tradesGroup).length >= 1)
    )
      return false
    return true
  }

  const disabledUnGroupDeal = () => {
    if (Object.keys(dealsGroup).length >= 0 && Object.keys(tradesGroup).length === 0) return false
    return true
  }

  const disabledAmendTrade = () => {
    if (Object.keys(tradesGroup).length + Object.keys(dealTradesGroup).length !== 1) return true
    return false
  }

  const disabledDealGiveUp = () => {
    if (Object.keys(dealsGiveUpGroup).length === 0) return true
    return false
  }

  const disabledDeleteTradeDeal = () => {
    if (Object.keys(dealsGroup).length > 0 || Object.keys(tradesGroup).length > 0) return false
    return true
  }

  return {
    handelAllocateTrade,
    handelTradeDealDelete,
    handelGroupButtonRender,
    handelDealGiveUp,
    handelUnGroupDeals,
    handelAmendTrade,
    handelGroupTrade,
    disabledGroupTrade,
    disabledUnGroupDeal,
    disabledAmendTrade,
    disabledDealGiveUp,
    disabledDeleteTradeDeal
  }
}

export default useToolBarSetting
