import React from 'react'
import PropTypes from 'prop-types'
import {
  ContainerButtons,
  ButtonAmendTrade,
  ButtonDeleted,
  ButtonGiveUp,
  ButtonGroupTrade,
  IconButtonStyle,
  TradeBlotterTool
} from '../../TradeBlotter.style'
import { Box } from '@mui/material'
import MenuButton from '../MenuButton/MenuButton'
import FilterTable from '../FilterTable/FilterTable'
import SearchTextField from '../../../../components/SearchTextField/SearchTextField'
import { ReactComponent as WarningIcon } from '../../../../assets/warning.svg'
import { ReactComponent as UnionIcon } from '../../../../assets/union.svg'

const TradeBlotterToolBar = ({
  handelGroupButtonRender,
  handelGroupTrade,
  disabledGroupTrade,
  handelUnGroupDeals,
  disabledUnGroupDeal,
  handelAmendTrade,
  disabledAmendTrade,
  handelDealGiveUp,
  disabledDealGiveUp,
  handelTradeDealDelete,
  disabledDeleteTradeDeal,
  filters,
  setFilters,
  handelGlobalFilterIcon,
  page,
  setPage,
  cleanCheckBoxState,
  handelGlobalSearchFilter,
  handelKeyPress
}) => {
  return (
    <>
      <TradeBlotterTool>
        <ContainerButtons>
          {handelGroupButtonRender() ? (
            <ButtonGroupTrade
              variant="contained"
              onClick={handelGroupTrade}
              disabled={disabledGroupTrade()}
            >
              Group
            </ButtonGroupTrade>
          ) : (
            <ButtonGroupTrade
              variant="contained"
              onClick={handelUnGroupDeals}
              disabled={disabledUnGroupDeal()}
            >
              Ungroup
            </ButtonGroupTrade>
          )}
          <ButtonAmendTrade
            variant="contained"
            onClick={handelAmendTrade}
            disabled={disabledAmendTrade()}
          >
            Amend
          </ButtonAmendTrade>

          <ButtonGiveUp
            variant="contained"
            onClick={handelDealGiveUp}
            disabled={disabledDealGiveUp()}
          >
            Give-Up
          </ButtonGiveUp>
          <ButtonDeleted
            variant="contained"
            onClick={handelTradeDealDelete}
            disabled={disabledDeleteTradeDeal()}
          >
            Delete
          </ButtonDeleted>
        </ContainerButtons>
        <Box style={{ display: 'flex', alignItems: 'center', gap: '12px', flexWrap: 'wrap' }}>
          <IconButtonStyle
            press={String(filters.status_warning)}
            onClick={() => handelGlobalFilterIcon('status_warning')}
          >
            <WarningIcon />
          </IconButtonStyle>
          <IconButtonStyle
            press={String(filters.status_union)}
            onClick={() => handelGlobalFilterIcon('status_union')}
          >
            <UnionIcon />
          </IconButtonStyle>

          <SearchTextField
            name="search"
            handleChange={handelGlobalSearchFilter}
            handelKeyPress={handelKeyPress}
            state={filters.search}
          />
          <MenuButton />
          <FilterTable
            page={page}
            WSMessageType={'get_trade_blotter'}
            filters={filters}
            setFilters={setFilters}
            cleanCheckBoxState={cleanCheckBoxState}
            setPage={setPage}
          />
        </Box>
      </TradeBlotterTool>
    </>
  )
}
export default TradeBlotterToolBar

TradeBlotterToolBar.propTypes = {
  handelGroupButtonRender: PropTypes.func,
  handelGroupTrade: PropTypes.func,
  disabledGroupTrade: PropTypes.func,
  handelUnGroupDeals: PropTypes.func,
  disabledUnGroupDeal: PropTypes.func,
  handelAmendTrade: PropTypes.func,
  disabledAmendTrade: PropTypes.func,
  handelAllocateTrade: PropTypes.func,
  disabledAllocateTrade: PropTypes.func,
  handelDealGiveUp: PropTypes.func,
  disabledDealGiveUp: PropTypes.func,
  handelTradeDealDelete: PropTypes.func,
  disabledDeleteTradeDeal: PropTypes.func,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  handelGlobalFilterIcon: PropTypes.func,
  page: PropTypes.number,
  setPage: PropTypes.func,
  cleanCheckBoxState: PropTypes.func,
  handelGlobalSearchFilter: PropTypes.func,
  handelKeyPress: PropTypes.func
}
