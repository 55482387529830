import { Grid, styled, Typography } from '@mui/material'

export const ChartContainer = styled(Grid)({
  height: '100%',
  width: '100%'
})

export const TypographyChart = styled(Typography)({
  position: 'fixed',
  top: '50%',
  bottom: '50%',
  left: '55%',
  color: '#BABEC7',
  textAlign: 'center',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Nunito',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal'
})
