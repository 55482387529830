import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from '../../../../pages/Login/Login'
import routers from '../../../../utils/router'
import EntityDetails from '../../../../pages/Entities/EntityDetails/EntityDetails'
import RedirectToDashboard from '../RedirectToDashboard/RedirectToDashboard'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      {routers.map((route, index) => {
        const { path, element } = route
        return <Route key={index} path={path} element={element} />
      })}
      <Route
        path="/entities/:entityId/:counterpartyType/:tradingAccountId"
        element={<EntityDetails />}
      />
      <Route path="/entities/:entityId" element={<EntityDetails />} />
      <Route path="*" element={<RedirectToDashboard />} />
    </Routes>
  )
}

export default PrivateRoutes
