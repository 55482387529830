/* eslint-disable */
import React, { useState, useEffect, useMemo } from 'react'
import { Grid } from '@mui/material'
import { CloseButton, SaveButton } from './ClientMmDetails.style'
import { MODAL_TYPE, routes } from '../../../utils/constants'
import useAxios from '../../../hooks/useAxios'
import PropTypes from 'prop-types'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { handelNotifications } from '../../../redux/slices/Notification/notificationSlice'
import currencyList from '../../../utils/currencyList.json'
import LuceraDropValue from './components/LuceraDropValue'
import ProductPermission from './components/ProductPermission'
import CounterpartySetting from './components/CounterpartySetting'
import TradingAccountHeader from './components/TradingAccountHeader'
import useLuceraDropValue from './hook/useLuceraDropValue'
import useProductPermission from './hook/useProductPermission'
import BookingRecaption from '../../../components/BookingRecaption/BookingRecaption'
import useBookingRecaption from '../../../components/BookingRecaption/hook/useBookingRecaption'

function ClientMmDetails({ clientMmId, userType, getEntitiesDetails }) {
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const [productPermissionError, setProductPermissionError] = useState({})
  const [search, setSearch] = useState({ search: '' })
  const [form, setForm] = useState({
    lucera_drop_value: '',
    makor_company: '',
    emails: '',
    prime_broker_mapping: '',
    limit_m: '',
    makor_prime_broker: '',
    is_structured: true
  })
  const [makorPrimeBroker, setMakorPrimeBroker] = useState(false)
  const [active, setActive] = useState(true)
  const [role, setRole] = useState('Client')
  const [entity, setEntity] = useState('Makor Securities London')
  const [giveUp, setGiveUp] = useState(true)
  const [activeButton, setActiveButton] = useState('Spot')
  const [currencyPermissionData, setCurrencyPermissionData] = useState([])
  const modal = useSelector(state => state.modal)
  const buttonLabels = useMemo(
    () => ['Spot', 'Forwards', 'Vanilla Options', 'Exotic Options', 'Swap'],
    []
  )

  const request = {
    endPoint: routes.contractDistribution,
    params: search
  }
  const { response: contractDistribution, setResponse: setResponseContractDistribution } =
    useAxios(request)

  const [currencyChange, seCurrencyChange] = useState([])
  const [currencyPermissionChange, seCurrencyPermissionChange] = useState({})

  const { addLuceraRows, handelLuceraDropValue, handleDeleteLuceraValue } = useLuceraDropValue({
    form,
    setForm
  })

  const { submitNewContract, handelEmail } = useBookingRecaption({
    setSearchEmail: setSearch,
    searchEmail: search,
    form,
    setForm,
    contractDistribution,
    setResponseContractDistribution
  })

  const { handleNavButtonClick, handleTenorChange, handelPermissionClick } = useProductPermission({
    setActiveButton,
    clientMmId,
    token,
    setCurrencyPermissionData,
    seCurrencyPermissionChange,
    currencyPermissionData,
    setProductPermissionError,
    seCurrencyChange,
    userType
  })

  const getMarketMakerById = async () => {
    const url = process.env.REACT_APP_SERVER_URL.concat(routes.marketMaker, '/', clientMmId)
    try {
      const result = await axios.get(url, { headers: { Authorization: token } })
      const updatedLuceraDropValue = result.data.lucera_drop_value
      setForm({
        lucera_drop_value: updatedLuceraDropValue,
        market_maker_name: result.data.name,
        makor_company: result.data.makor_company,
        emails: result.data.market_maker_mailing_list,
        prime_broker_mapping: result.data.prime_broker_mapping,
        limit_m: result.data.limit_m,
        makor_prime_broker: result.data.makor_prime_broker,
        is_structured: !!result.data.is_structured
      })
      setMakorPrimeBroker(result.data.makor_prime_broker)
    } catch (error) {
      console.log(error)
    }
  }

  const getClientById = async () => {
    const url = process.env.REACT_APP_SERVER_URL.concat(routes.client, '/', clientMmId)
    try {
      const result = await axios.get(url, { headers: { Authorization: token } })
      const updatedLuceraDropValue = result.data.lucera_drop_value
      setForm({
        lucera_drop_value: updatedLuceraDropValue,
        client_name: result.data.name,
        makor_company: result.data.makor_company,
        emails: result.data.client_mailing_list,
        prime_broker_mapping: result.data.prime_broker_mapping,
        limit_m: result.data.limit_m,
        makor_prime_broker: result.data.makor_prime_broker,
        is_structured: !!result.data.is_structured
      })
      setMakorPrimeBroker(result.data.makor_prime_broker)
    } catch (error) {
      console.log(error)
    }
  }

  const submit = async () => {
    const urlClient = process.env.REACT_APP_SERVER_URL.concat(routes.client)
    const urlMm = process.env.REACT_APP_SERVER_URL.concat(routes.marketMaker)
    try {
      let luceraValue = form.lucera_drop_value
      const hasLuceraEmptyValues = luceraValue.some(item => item.type === '' || item.lucera === '')

      if (hasLuceraEmptyValues) {
        dispatch(
          handelNotifications({
            id: Date.now(),
            type: 'error',
            message: 'Lucera value cannot be empty'
          })
        )
        return
      }
      const data = { ...form, id: clientMmId }
      delete data.limit_m
      if (userType === 'client') {
        await axios.put(urlClient, data, { headers: { Authorization: token } })
        await getClientById()
      } else {
        await axios.put(urlMm, { ...form, id: clientMmId }, { headers: { Authorization: token } })
        await getMarketMakerById()
      }
      if (makorPrimeBroker !== form.makor_prime_broker) {
        await getEntitiesDetails()
      }
    } catch (error) {
      if (Array.isArray(error?.response?.data))
        for (const { message } of error.response.data) {
          dispatch(
            handelNotifications({
              id: Date.now(),
              type: 'error',
              message: message
            })
          )
        }
    }
  }

  const handelFocus = (e, tenor) => {
    const productPermissionErrorsCopy = JSON.parse(JSON.stringify(productPermissionError))
    delete productPermissionErrorsCopy[tenor]
    setProductPermissionError(productPermissionErrorsCopy)
  }

  const handelBlur = (e, tenor) => {
    const { value } = e.target
    const productPermissionErrorsCopy = JSON.parse(JSON.stringify(productPermissionError))
    if (value === '') {
      if (!productPermissionErrorsCopy[tenor]) {
        delete productPermissionErrorsCopy[tenor]
        setProductPermissionError(productPermissionErrorsCopy)
      }
      return
    }
    const errorFlag = value.split(',').some(c => c.length !== 3)
    if (errorFlag) {
      setProductPermissionError({
        ...productPermissionError,
        [tenor]: `all currency must be 3 upper letters separate with ,`
      })
    }
  }
  const submitPermission = async () => {
    const getMissingOrIds = findMissingCurrencies(currencyChange)
    if (getMissingOrIds.error === true) {
      dispatch(
        handelNotifications({
          id: Date.now(),
          type: 'error',
          message: getMissingOrIds.message
        })
      )
      seCurrencyChange([])
    } else {
      let type
      if (activeButton === 'Spot') {
        type = 1
      } else if (activeButton === 'Forwards') {
        type = 2
      } else if (activeButton === 'Vanilla Options') {
        type = 4
      } else if (activeButton === 'Exotic Options') {
        type = 5
      } else if (activeButton === 'Swap') {
        type = 3
      }
      if (userType === 'client') {
        const tenorLimitId = currencyPermissionChange.tenor_limit_id
        let url = process.env.REACT_APP_SERVER_URL.concat(
          routes.client,
          '/',
          clientMmId,
          '/',
          type,
          '/',
          tenorLimitId
        )
        try {
          if (
            tenorLimitId &&
            getMissingOrIds.currencyIds.length &&
            Object.keys(productPermissionError).length !== 0
          ) {
            await axios.put(url, getMissingOrIds.currencyIds, {
              headers: { Authorization: token }
            })
          }
        } catch (error) {
          console.log(error.response.data)
        }
      } else {
        const tenorLimitId = currencyPermissionChange.tenor_limit_id
        let url = process.env.REACT_APP_SERVER_URL.concat(
          routes.marketMaker,
          '/',
          clientMmId,
          '/',
          type,
          '/',
          tenorLimitId
        )
        try {
          if (
            tenorLimitId &&
            getMissingOrIds.currencyIds.length &&
            Object.keys(productPermissionError).length !== 0
          ) {
            await axios.put(url, getMissingOrIds.currencyIds, {
              headers: { Authorization: token }
            })
          }
        } catch (error) {
          console.log(error.response.data)
        }
      }
    }
  }

  const handelBookingRecaption = event => {
    const { value, checked } = event.target
    const copyForm = JSON.parse(JSON.stringify(form))
    if (checked) {
      copyForm.emails.push(value)
    } else if (!checked) {
      const elementIndex = copyForm.emails.indexOf(value)
      copyForm.emails.splice(elementIndex, 1)
    }
    setForm(copyForm)
  }

  const findMissingCurrencies = currenciesToCheck => {
    const availableCurrenciesMap = new Map(currencyList.map(item => [item.name, item.id]))
    const missingCurrencies = currenciesToCheck.filter(
      currency => !availableCurrenciesMap.has(currency)
    )

    if (missingCurrencies.length > 0) {
      return {
        error: true,
        message: `The following currencies do not exist: ${missingCurrencies.join(', ')}`,
        missingCurrencies: missingCurrencies
      }
    } else {
      const currencyIds = currenciesToCheck.map(currency => availableCurrenciesMap.get(currency))
      return {
        error: false,
        message: 'All currencies exist',
        currencyIds: currencyIds
      }
    }
  }

  useEffect(() => {
    if (userType === 'client') {
      setRole('Client')
      getClientById()
      handleNavButtonClick(activeButton, userType)
    } else if (userType === 'market maker') {
      setRole('Market Maker')
      getMarketMakerById()
      handleNavButtonClick(activeButton, userType)
    }
  }, [clientMmId, userType])

  useEffect(() => {
    if (
      modal.type == MODAL_TYPE.PERMISSION &&
      modal.getRequest === true &&
      activeButton === modal.data.product_type
    ) {
      handleNavButtonClick(activeButton, userType)
    }
  }, [modal, activeButton, userType])

  return (
    <Grid
      container
      style={{
        gap: '24px',
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        alignItems: 'start',
        alignContent: 'start'
      }}
    >
      <Grid item xs={12}>
        <TradingAccountHeader form={form} active={active} setActive={setActive} />
        <Grid
          container
          style={{
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            marginTop: '12px',
            height: 'auto'
          }}
        >
          <CounterpartySetting
            role={role}
            form={form}
            setForm={setForm}
            entity={entity}
            setEntity={setEntity}
            giveUp={giveUp}
            setGiveUp={setGiveUp}
          />
          <LuceraDropValue
            form={form}
            addLuceraRows={addLuceraRows}
            handelLuceraDropValue={handelLuceraDropValue}
            handleDeleteLuceraValue={handleDeleteLuceraValue}
          />
          {Array.isArray(contractDistribution.data) && (
            <Grid item xs={4} style={{ display: 'flex', flexDirection: 'column' }}>
              <BookingRecaption
                form={form}
                searchEmail={search}
                handelEmail={handelEmail}
                handelFormChange={handelBookingRecaption}
                contractDistribution={contractDistribution}
                submitNewContract={submitNewContract}
                style={{ background: '#242428', maxHeight: '300px', height: '290px' }}
                backgroundSearchBarContainer={'#242428'}
                backgroundSearchBar="#39393C"
                pageArea={'Entity'}
              />
            </Grid>
          )}
        </Grid>

        <ProductPermission
          buttonLabels={buttonLabels}
          activeButton={activeButton}
          userType={userType}
          limitM={form.limit_m}
          handleNavButtonClick={handleNavButtonClick}
          currencyPermissionData={currencyPermissionData}
          productPermissionError={productPermissionError}
          handelBlur={handelBlur}
          handelFocus={handelFocus}
          handleTenorChange={handleTenorChange}
          handelPermissionClick={handelPermissionClick}
        />
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            marginTop: '29px',
            marginBottom: '24px'
          }}
        >
          <CloseButton onClick={submit}>Save {userType}</CloseButton>
          <SaveButton onClick={submitPermission}>Save Product Permission</SaveButton>
        </Grid>
      </Grid>
    </Grid>
  )
}
ClientMmDetails.propTypes = {
  params: PropTypes.object,
  clientMmId: PropTypes.string,
  userType: PropTypes.string,
  getEntitiesDetails: PropTypes.func
}

export default ClientMmDetails
