import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { routes } from '../../../utils/constants'
import { userLogout } from '../Auth/AuthSlice'

const initialState = {
  data: {},
  loader: false
}

const entitySlice = createSlice({
  name: 'entity',
  initialState,
  reducers: {
    setEntity: (state, action) => {
      state.data = action.payload
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    }
  }
})

export const getEntityById = (id, navigate) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(setLoader(true))
  if (typeof Number(id) !== 'number') {
    navigate('/entitles')
  }
  const url = process.env.REACT_APP_SERVER_URL.concat(routes.entities, '/', id)
  try {
    const result = await axios.get(url, { headers: { Authorization: token } })
    dispatch(setEntity(result.data))
  } catch (error) {
    dispatch(userLogout())
  } finally {
    dispatch(setLoader(false))
  }
}

export const { setEntity, setLoader } = entitySlice.actions

export default entitySlice.reducer
