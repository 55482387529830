import { FormControlLabel, Grid } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import {
  IOSSwitch,
  Typography1Style,
  Typography2Style,
  Typography3Style,
  Typography4Style
} from '../ClientMmDetails.style'

const TradingAccountHeader = ({ form, active, setActive }) => {
  return (
    <Grid item xs={12}>
      {/* Trading Account Title */}
      <Grid
        item
        xs={12}
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Typography2Style>{form?.client_name || form.market_maker_name}</Typography2Style>
        <FormControlLabel
          control={<IOSSwitch checked={active} onChange={() => setActive(!active)} />}
          label={<Typography1Style>Active</Typography1Style>}
        />
      </Grid>
      {/* PB / Company Name */}
      <Grid
        item
        xs={12}
        style={{ display: 'flex', gap: '12px', alignItems: 'center', marginTop: '15px' }}
      >
        <Typography4Style>{form?.makor_prime_broker}</Typography4Style>
        <Typography3Style>{form?.makor_company}</Typography3Style>
      </Grid>
    </Grid>
  )
}

export default TradingAccountHeader

TradingAccountHeader.propTypes = {
  form: PropTypes.object,
  active: PropTypes.bool,
  setActive: PropTypes.func
}
