import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  type: '',
  title: '',
  data: {},
  isOpen: false,
  getRequest: false,
  disabled: false,
  action: null,
  method: ''
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModal: (state, action) => {
      state.type = action.payload.type
      state.title = action.payload.title
      state.data = action.payload.data || {}
      state.disabled = action.payload.disabled
      state.action = action.payload.action
      state.isOpen = action.payload.isOpen
      state.method = action.payload.method
      state.getRequest = action.payload.getRequest || false
    },
    setGetRequest: (state, action) => {
      state.getRequest = action.payload
    },
    closeModal: state => {
      state.type = ''
      state.title = ''
      state.data = {}
      state.disabled = false
      state.action = null
      state.isOpen = false
      state.method = ''
    }
  }
})

export const { setModal, closeModal, setGetRequest } = modalSlice.actions

export default modalSlice.reducer
