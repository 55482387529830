import { combineReducers } from '@reduxjs/toolkit'
import modalReducer from './slices/Modal/modalSlice'
import notificationReducer from './slices/Notification/notificationSlice'
import authReducer from './slices/Auth/AuthSlice'
import entityReducer from './slices/Entity/entitySlice'
import bloombergReducer from './slices/Bloomberg/bloombergSlice'
import widgetsSlice from './slices/widgets/WidgetsSlice'

const appReducer = combineReducers({
  auth: authReducer,
  bloomberg: bloombergReducer,
  entity: entityReducer,
  modal: modalReducer,
  notification: notificationReducer,
  widgets: widgetsSlice
})

export default appReducer
