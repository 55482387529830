import { styled } from '@mui/material'

export const HeaderStyle = styled('header')(({ theme }) => ({
  marginLeft: '4rem',
  backgroundColor: '#9999FF',
  height: '65px',
  flexShrink: 0,
  width: 'calc(100dvw - 4rem)',
  [theme.breakpoints.down('sm')]: {
    backgroundColor: '#000',
    width: 'max-content'
  }
}))
