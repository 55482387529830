import React, { useState } from 'react'
import {
  CancelButton,
  CardText,
  CardTitle,
  MyCard,
  SaveChangesButton
} from '../../components/styled/global.style'
import { Box, CardContent } from '@mui/material'
import { useDispatch } from 'react-redux'
import validator from 'validator'
import { ERRORS } from '../../utils/constants'
import { useNavigate } from 'react-router-dom'
import { resetAuthByEmail } from '../../redux/slices/Auth/AuthSlice'
import { ResetPasswordInput } from './ResetPassword.style'

const ResetPassword = () => {
  const [form, setForm] = useState({ email: '' })
  const [error, setError] = useState({ email: '' })
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const validateForm = () => {
    if (!validator.isEmail(form.email)) {
      setError({ email: ERRORS.email('email') })
      return false
    }
    return true
  }
  const handelBackButton = () => {
    navigate('/login')
  }

  const handelSubmit = async () => {
    if (validateForm()) {
      dispatch(resetAuthByEmail(form, navigate))
    }
  }

  const disabledButton = () => {
    return form.email === '' ? true : false
  }

  const handelChange = event => {
    const { name, value } = event.target
    setForm({ [name]: value })
  }

  const handelFocus = () => {
    setError({})
  }

  const handelBlur = () => {
    if (!validator.isEmail(form.email)) {
      setError({ email: 'not valid email' })
    }
  }

  return (
    <MyCard>
      <CardContent>
        <CardTitle>Reset your password</CardTitle>
        <Box style={{ marginTop: '40px', marginBottom: '32px' }}>
          <CardText>Please enter your email address to receive</CardText>
          <CardText>a link confirmation to reset your password.</CardText>
        </Box>
        <ResetPasswordInput
          name="email"
          onChange={handelChange}
          type="email"
          autoComplete="off"
          value={form.email}
          placeholder="email@example.com"
          error={!!error.email}
          onFocus={handelFocus}
          onBlur={handelBlur}
        ></ResetPasswordInput>
      </CardContent>
      <CardContent>
        <Box
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '45px'
          }}
        >
          <CancelButton variant="contained" onClick={handelBackButton}>
            Back
          </CancelButton>
          <SaveChangesButton variant="contained" onClick={handelSubmit} disabled={disabledButton()}>
            Continue
          </SaveChangesButton>
        </Box>
      </CardContent>
    </MyCard>
  )
}

export default ResetPassword
