import React from 'react'
import { ChartContainer, TypographyChart } from './ClientMMChartDetails.style'

function ClientMmDetails() {
  return (
    <ChartContainer>
      <TypographyChart>Select Trading Account</TypographyChart>
    </ChartContainer>
  )
}
export default ClientMmDetails
