import React from 'react'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import './AppDrawer.style.js'
import { ReactComponent as DrawerIcon } from '../../../assets/drawer icon.svg'
import router from '../../../utils/router.js'
import Divider from '@mui/material/Divider'
import { NavLink, useLocation } from 'react-router-dom'
import { Drawer, DrawerHeader } from './AppDrawer.style.js'

export default function AppDrawer() {
  const { pathname } = useLocation()

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer variant="permanent" open={false}>
        <DrawerHeader>
          <DrawerIcon />
        </DrawerHeader>
        <Divider />
        <List>
          {router.map(({ path, name, icon, selectIcon }) => (
            <ListItem key={name}>
              {pathname.includes(path) ? (
                <ListItemIcon style={{ marginLeft: '4px', marginRight: '8px' }}>
                  <NavLink to={path}> {selectIcon} </NavLink>
                </ListItemIcon>
              ) : (
                <ListItemIcon style={{ marginLeft: '4px', marginRight: '8px' }}>
                  <NavLink to={path}> {icon} </NavLink>
                </ListItemIcon>
              )}
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  )
}
