import { createSlice } from '@reduxjs/toolkit'
import { handelNotifications } from '../Notification/notificationSlice'
import { routes } from '../../../utils/constants'
import axios from 'axios'
import { webSocketWorkerEvent, webSocketWorkerPriceEvent } from '../../../services/index'
import router from '../../../utils/router'
import { closeModal } from '../Modal/modalSlice'

const initialState = {
  entitlements: [],
  email: '',
  title: '',
  token: sessionStorage.getItem('token') ?? '',
  username: ''
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.entitlements = action.payload.entitlements
      state.token = `Bearer ${action.payload.token}`
      state.username = action.payload.username
      state.email = action.payload.email
      sessionStorage.setItem('token', state.token)
    },
    clearAuth: state => {
      sessionStorage.clear()
      state.entitlements = []
      state.email = ''
      state.title = ''
      state.token = ''
      state.username = ''
    },
    setHeaderName: (state, action) => {
      state.title = action.payload.title
    }
  }
})

export const resetAuthByEmail = (form, navigate) => async dispatch => {
  const url = process.env.REACT_APP_SERVER_URL.concat(routes.resetPasswordEmail)
  try {
    await axios.put(url, form)
    navigate('/sent/email')
  } catch (error) {
    if (Array.isArray(error?.response?.data)) {
      for (const { message } of error.response.data) {
        dispatch(
          handelNotifications({
            id: Date.now(),
            type: 'error',
            message: message
          })
        )
      }
    }
  }
}

export const resetNewPassword = (form, token, navigate) => async dispatch => {
  const url = process.env.REACT_APP_SERVER_URL.concat(routes.resetPassword)
  try {
    const data = { ...form, token }
    const result = await axios.put(url, data)
    dispatch(setAuth(result.data))
    webSocketWorkerEvent.connectWs(result.data.token)

    navigate('/dashboard')
  } catch (error) {
    if (Array.isArray(error?.response?.data)) {
      for (const { message } of error.response.data) {
        dispatch(
          handelNotifications({
            id: Date.now(),
            type: 'error',
            message: message
          })
        )
      }
    }
  }
}

export const loginUser = (form, navigate) => async dispatch => {
  try {
    const url = `${process.env.REACT_APP_SERVER_URL}${routes.auth}`
    const result = await axios.post(url, form)
    if (result.data.token && !('message' in result.data)) {
      dispatch(setAuth(result.data))
      navigate('/dashboard')
      webSocketWorkerEvent.connectWS(`${result.data.token}`)
      webSocketWorkerPriceEvent.connectPricerWS()
    } else {
      navigate(`/change/password/${result.data.token}`)
    }
  } catch (error) {
    if (Array.isArray(error?.response?.data))
      for (const { message } of error.response.data) {
        dispatch(
          handelNotifications({
            id: Date.now(),
            type: 'error',
            message: message
          })
        )
      }
  }
}

export const userLogout = navigate => dispatch => {
  dispatch(clearAuth())
  navigate('/login')
  dispatch(closeModal())
  webSocketWorkerEvent.closeWS()
  webSocketWorkerPriceEvent.closeWS()
}

export const automaticLogin = (navigate, pathname) => async (dispatch, getState) => {
  const token = getState().auth.token
  if (!token) {
    navigate('/login')
    return
  }
  const wsToken = token.substring('Bearer '.length)
  const tokenPayload = JSON.parse(atob(wsToken.split('.')[1]))
  const expirationTimeInSeconds = tokenPayload.exp * 1000

  if (Date.now() < expirationTimeInSeconds) {
    webSocketWorkerEvent.connectWS(wsToken)
    if (pathname.startsWith('/entities')) {
      navigate(pathname)
      dispatch(setHeaderName({ title: 'Entities' }))
      return
    }
    const result = router.find(({ path }) => path === pathname)
    if (result) {
      navigate(pathname)
      dispatch(setHeaderName({ title: result.name }))
    } else {
      navigate('/dashboard')
      dispatch(setHeaderName({ title: 'Dashboard' }))
    }
  } else {
    dispatch(userLogout(navigate))
  }
}

export const { setAuth, clearAuth, setHeaderName } = authSlice.actions

export default authSlice.reducer
