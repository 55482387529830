import { Grid, Typography } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import { HSBCContainer, RBSContainer } from '../../Entities.style'
import { ButtonAddNew } from '../EntitesSide.style'
import { useDispatch } from 'react-redux'
import { setModal } from '../../../../redux/slices/Modal/modalSlice'
import { METHODS, MODAL_TITLE, MODAL_TYPE } from '../../../../utils/constants'

const TradingAccount = ({
  entityPrimeBroker,
  clientMmId,
  handleClientClicked,
  entityName,
  userType
}) => {
  const dispatch = useDispatch()

  const createNewTradingAccount = () => {
    dispatch(
      setModal({
        type: MODAL_TYPE.TRADING_ACCOUNT,
        title: MODAL_TITLE.NEW_TRADING_ACCOUNT,
        isOpen: true,
        method: METHODS.POST,
        data: { entity_name: entityName }
      })
    )
  }

  return (
    <>
      <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          style={{
            color: 'var(--Purple-3, #7373BB)',
            leadingTrim: 'both',
            textEdge: 'cap',
            fontFamily: 'Nunito',
            fontSize: '18px',
            fontStyle: 'normal',
            marginBottom: '16px',
            fontWeight: '500',
            lineHeight: 'normal'
          }}
        >
          Trading Accounts
        </Typography>
        <ButtonAddNew onClick={createNewTradingAccount}>Add New</ButtonAddNew>
      </Grid>

      {entityPrimeBroker.status !== '' &&
        entityPrimeBroker.data.primeBrokers.sort().map((primeBroker, index) => {
          if (primeBroker !== 'HSBC') {
            return (
              <Grid item key={index} style={{ marginBottom: '24px' }}>
                <Grid
                  item
                  style={{
                    padding: '16px 24px',
                    background: 'var(--Inputs-on-modal, #39393C)',
                    borderRadius: '4px 4px 0px 0px'
                  }}
                >
                  <Typography
                    style={{
                      color: 'var(--Primary-text, #FFF)',
                      leadingTrim: 'both',
                      textEdge: 'cap',
                      fontFamily: 'Nunito',
                      fontSize: '18px',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: 'normal'
                    }}
                  >
                    {primeBroker}
                  </Typography>
                </Grid>
                <Grid
                  item
                  style={{ borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px' }}
                >
                  {entityPrimeBroker.data[primeBroker].map((key, index) => {
                    return (
                      <RBSContainer
                        key={key}
                        length={String(entityPrimeBroker.data[primeBroker].length)}
                        index={String(index)}
                        select={
                          String(+clientMmId === key.id) &&
                          userType.toLowerCase() === key.type.toLowerCase()
                        }
                        onClick={() =>
                          handleClientClicked(
                            key.name,
                            key.id,
                            key.type.toLowerCase().replaceAll('-', ' ')
                          )
                        }
                      >
                        <Typography
                          style={{
                            color:
                              key.id === +clientMmId &&
                              userType.toLowerCase() === key.type.toLowerCase()
                                ? '#A4A4FF'
                                : '#FFF',
                            fontSize: '16px',
                            lineHeight: '1',
                            marginBottom: '8px'
                          }}
                        >
                          {key.name}
                        </Typography>
                        <Grid
                          style={{
                            display: 'flex',
                            fontSize: '16px',
                            gap: '4px',
                            lineHeight: '1'
                          }}
                        >
                          <Typography style={{ color: '#707079' }}>{key.type}</Typography>
                          <Typography style={{ color: '#BABEC7' }}>
                            {'|'} {key.location}
                          </Typography>
                        </Grid>
                      </RBSContainer>
                    )
                  })}
                </Grid>
              </Grid>
            )
          } else if (primeBroker === 'HSBC') {
            return (
              <Grid item key={index} style={{ marginBottom: '24px' }}>
                <Grid
                  item
                  style={{
                    padding: '16px 24px',
                    background: 'var(--Inputs-on-modal, #39393C)',
                    borderRadius: '4px 4px 0px 0px'
                  }}
                >
                  <Typography
                    style={{
                      color: 'var(--Primary-text, #FFF)',
                      leadingTrim: 'both',
                      textEdge: 'cap',
                      fontFamily: 'Nunito',
                      fontSize: '18px',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: 'normal'
                    }}
                  >
                    {primeBroker}
                  </Typography>
                </Grid>

                <Grid
                  item
                  style={{ borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px' }}
                >
                  {entityPrimeBroker.data[primeBroker].map((key, index) => {
                    return (
                      <HSBCContainer
                        key={key}
                        length={String(entityPrimeBroker.data[primeBroker].length)}
                        index={String(index)}
                        select={
                          String(+clientMmId === key.id) &&
                          userType.toLowerCase() === key.type.toLowerCase()
                        }
                        onClick={() =>
                          handleClientClicked(
                            key.name,
                            key.id,
                            key.type.toLowerCase().replaceAll('-', ' ')
                          )
                        }
                      >
                        <Typography
                          style={{
                            color:
                              String(+clientMmId === key.id) &&
                              userType.toLowerCase() === key.type.toLowerCase()
                                ? '#A4A4FF'
                                : '#FFF',
                            fontSize: '16px',
                            lineHeight: '1',
                            marginBottom: '8px'
                          }}
                        >
                          {key.name}
                        </Typography>
                        <Grid
                          style={{
                            display: 'flex',
                            marginBottom: '8px',
                            fontSize: '16px',
                            gap: '4px',
                            lineHeight: '1'
                          }}
                        >
                          <Typography style={{ color: '#707079' }}>
                            {key.type}
                            {key.name}
                          </Typography>
                          <Typography style={{ color: '#BABEC7' }}>
                            {'|'} {key.location}
                          </Typography>
                        </Grid>
                      </HSBCContainer>
                    )
                  })}
                </Grid>
              </Grid>
            )
          }
        })}
    </>
  )
}

export default TradingAccount

TradingAccount.propTypes = {
  entityPrimeBroker: PropTypes.object,
  handleClientClicked: PropTypes.func,
  clientMmId: PropTypes.number,
  entityName: PropTypes.string,
  userType: PropTypes.string
}
