import { useEffect } from 'react'
import { webSocketWorkerEvent } from '../../../services'
import {
  changeUtcTimeToLocal,
  checkObjectAndArrayWithStringify,
  paginationHandler
} from '../../../helper'
import { DATE_FORMAT } from '../../../utils/constants'

const useTradeBlotterRequestHandler = ({
  gridScrollHight,
  tradeBlotterResponse,
  filters,
  selected,
  disabled,
  page,
  tradesGroup,
  dealsGroup,
  pendingData,
  setTradeBlotterResponse,
  setPendingData,
  setLoading,
  setDisabled,
  dealTradesGroup,
  getWidgets,
  setPagination,
  setDealsGroup,
  setTradesGroup,
  setDealsGiveUpGroup,
  setDealTradesGroup,
  selectedAllDeal,
  setSelectedAllDeal,
  setSelected,
  dispatchNotification
}) => {
  useEffect(() => {
    webSocketWorkerEvent.sendEvent({ type: 'get_trade_blotter', data: {} })
  }, [])

  useEffect(() => {
    getWidgets()
  }, [])

  useEffect(() => {
    const handelMessage = message => {
      if (
        (message.data.type === 'get_trade_blotter' ||
          message.data.type === 'get_trade_blotter_lucera' ||
          message.data.type === 'get_trade_blotter_letool') &&
        message.data.status === 'ok' &&
        message.data.data &&
        Array.isArray(message.data.data.tradeBlotterData)
      ) {
        let { status, data } = message.data
        const { tradeBlotterData, filters, max_deals } = data
        if (tradeBlotterData.length === 0) {
          setTradeBlotterResponse({
            status,
            data: tradeBlotterData
          })
          setLoading(false)
          return
        }
        setPagination(requests => {
          if (!checkObjectAndArrayWithStringify(requests, filters)) {
            requests[page] = filters
          }
          return requests
        })

        setSelectedAllDeal({ ...selectedAllDeal, maxDeals: max_deals })

        for (const element of tradeBlotterData) {
          element.trade_date = changeUtcTimeToLocal(
            element.trade_date,
            DATE_FORMAT.BLOTTER_DATE_TIME
          )
          if (Array.isArray(element.trades)) {
            for (const trade of element.trades) {
              trade.trade_date = changeUtcTimeToLocal(
                trade.trade_date,
                DATE_FORMAT.BLOTTER_DATE_TIME
              )
            }
          }
        }
        paginationHandler(tradeBlotterData, page, { setDisabled, disabled })
        if (['get_trade_blotter_lucera', 'get_trade_blotter_letool'].includes(message.data.type)) {
          if (gridScrollHight.current.top > 200) {
            setPendingData({
              status,
              data: tradeBlotterData
            })
          } else {
            setTradeBlotterResponse({
              status,
              data: tradeBlotterData
            })
            if (pendingData.status !== null) {
              setPendingData({
                status: null,
                data: []
              })
            }
          }
        } else {
          if (pendingData.status !== null) {
            setPendingData({ data: [], status: null })
          }
          setTradeBlotterResponse({
            status,
            data: tradeBlotterData
          })
        }
        setLoading(false)
      }

      const SUCCESS = 'success'
      if (
        message.data.type === 'update_trade' &&
        message.data.status === 'ok' &&
        message.data.data.trade_id
      ) {
        const id = Number(message.data.data.trade_id)
        dispatchNotification(`trade: ${id} is being update`, SUCCESS)
        if (id in tradesGroup || id in dealTradesGroup) {
          setSelected(select => select - 1)
        }

        setTradesGroup(lastTradeGroups => {
          delete lastTradeGroups[id]
          return lastTradeGroups
        })
        setDealTradesGroup(lastTradeGroups => {
          delete lastTradeGroups[id]
          return lastTradeGroups
        })
      }

      if (message.data.type === 'delete_trades_or_deals' && message.data.status === 'ok') {
        if (Array.isArray(message.data.data.trades_id) && message.data.data.trades_id.length > 0) {
          const { trades_id } = message.data.data
          dispatchNotification(`trades: ${trades_id.join(',')} is being delete`, SUCCESS)
          setDealTradesGroup(lastTradeGroups => {
            let counter = 0
            for (const tradeId of trades_id) {
              const id = Number(tradeId)
              if (id in lastTradeGroups) {
                counter++
                delete lastTradeGroups[id]
              }
            }
            setSelected(select => select - counter)
            return lastTradeGroups
          })

          setTradesGroup(lastTradesGroup => {
            let counter = 0
            for (const tradeId of trades_id) {
              const id = Number(tradeId)
              if (id in lastTradesGroup) {
                counter++
                delete lastTradesGroup[id]
              }
            }
            setSelected(select => select - counter)
            return lastTradesGroup
          })
        }

        if (Array.isArray(message.data.data.deals_id) && message.data.data.deals_id.length > 0) {
          const { deals_id } = message.data.data
          dispatchNotification(`deals: ${deals_id.join(',')} is being delete`, SUCCESS)

          setDealsGroup(lastDealGroup => {
            let counter = 0
            for (const dealId of deals_id) {
              const id = Number(dealId)
              if (id in lastDealGroup) {
                delete lastDealGroup[id]
                counter++
              }
            }
            setSelected(select => select - counter)
            return lastDealGroup
          })
          setDealsGiveUpGroup(lastDealGiveUp => {
            for (const dealId of deals_id) {
              const id = Number(dealId)
              if (id in lastDealGiveUp) {
                delete lastDealGiveUp[id]
              }
            }
            return lastDealGiveUp
          })
        }
      }
      if (
        message.data.type === 'group_trades' &&
        message.data.status === 'ok' &&
        Array.isArray(message.data?.data?.trades_id)
      ) {
        const { trades_id } = message.data.data
        const tradesId = trades_id.map(tradeId => Number(tradeId))
        dispatchNotification(`trades: ${tradesId.join(',')} are being group to a deal`, SUCCESS)
        setTradesGroup(lastTradeGroups => {
          let counter = 0
          for (const tradeId of trades_id) {
            const id = Number(tradeId)
            if (id in lastTradeGroups) {
              counter++
              delete lastTradeGroups[id]
            }
          }
          setSelected(select => select - counter)
          return lastTradeGroups
        })
      }
      if (
        message.data.type === 'un_group_deals' &&
        message.data.status === 'ok' &&
        Array.isArray(message.data?.data?.deals_id)
      ) {
        const { deals_id } = message.data.data
        const dealsId = deals_id.map(dealId => Number(dealId))
        dispatchNotification(`deals: ${dealsId.join(',')} is/are being ungrouped`, SUCCESS)

        setDealsGroup(lastDealGroups => {
          let counter = 0
          for (const id of dealsId) {
            if (id in lastDealGroups) {
              counter++
              delete lastDealGroups[id]
            }
          }
          setSelected(select => select - counter)
          return lastDealGroups
        })
        setDealsGiveUpGroup(lastDealGiveUpGroup => {
          for (const id of dealsId) {
            delete lastDealGiveUpGroup[id]
          }
          return lastDealGiveUpGroup
        })
      }
      if (
        message.data.type === 'give_up_deals' &&
        message.data.status === 'ok' &&
        Array.isArray(message.data?.data?.deals_id)
      ) {
        const { deals_id } = message.data.data
        const dealsId = deals_id.map(dealId => Number(dealId))
        dispatchNotification(`deals: ${dealsId.join(',')} is/are being Live`, SUCCESS)
        setDealsGiveUpGroup(lastDealGiveUpGroup => {
          for (const id of dealsId) {
            delete lastDealGiveUpGroup[id]
          }
          return lastDealGiveUpGroup
        })
      }
    }

    webSocketWorkerEvent.addEventListener('message', handelMessage)
    return () => webSocketWorkerEvent.removeEventListener('message', handelMessage)
  }, [
    webSocketWorkerEvent,
    gridScrollHight,
    tradeBlotterResponse,
    filters,
    selected,
    disabled,
    page,
    selectedAllDeal,
    tradesGroup,
    dealsGroup
  ])
}

export default useTradeBlotterRequestHandler
