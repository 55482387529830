import React, { useDeferredValue, useRef, useState } from 'react'
import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import {
  AutocompleteStyle,
  FormError,
  InputBaseStyle,
  ModalContainer,
  TextFieldModalStyle
} from '../../ModalApp.style'
import { FormControlLabelStyle, Label } from '../../../styled/global.style'
import { ERRORS, METHODS, routes } from '../../../../utils/constants'
import useAxios from '../../../../hooks/useAxios'
import { checkSomeLoader, disabledSubmit } from '../../../../helper'
import Loader from '../../../Loader/Loader'
import PaperAutoComplete from '../../../PaperAutoComplete/PaperAutoComplete'
import ModalBottom from '../../components/ModalBottom/ModalBottom'
import BookingRecaption from '../../../BookingRecaption/BookingRecaption'
import useBookingRecaption from '../../../BookingRecaption/hook/useBookingRecaption'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { EntitiesLeftContainer, CheckboxStyle } from './Entities.style.js'

const Entities = ({ data, method }) => {
  let loaderFirstTime = useRef(true).current
  const [errors, setErrors] = useState({})
  const [searchEmail, setSearchEmail] = useState({ search: '' })
  const deferredEmail = useDeferredValue(searchEmail)

  const request1 = {
    endPoint: routes.country
  }

  const request2 = {
    endPoint: routes.contractDistribution,
    params: deferredEmail
  }

  const { response: countries, loading: countriesLoader, sendFormData } = useAxios(request1)
  const {
    response: contractDistribution,
    loading: contractDisributionLoader,
    setResponse: setResponseContractDistribution
  } = useAxios(request2)

  const loaderList = [countriesLoader, contractDisributionLoader]

  const [form, setForm] = useState({
    entity_name: data.entity_name ?? '',
    lei: data.lei ?? '',
    country: data.country ?? '',
    emails: Array.isArray(data.mailing_list) ? data.mailing_list : [],
    pba: typeof data.pba === 'number' ? !!data.pba : false,
    swap_dealer: typeof data.swap_dealer === 'number' ? !!data.swap_dealer : false
  })

  const { handelEmail, submitNewContract } = useBookingRecaption({
    setSearchEmail,
    searchEmail,
    form,
    setForm,
    contractDistribution,
    setResponseContractDistribution
  })

  const handelFormChange = (event, element) => {
    const { name, value, checked } = event.target
    const copyForm = JSON.parse(JSON.stringify(form))
    if (['entity_name', 'lei'].includes(name)) {
      copyForm[name] = value
      setForm(copyForm)
    } else if (['country'].includes(name)) {
      copyForm[name] = element
      setForm(copyForm)
    } else if (['emails'].includes(name)) {
      if (checked) {
        copyForm[name].push(value)
      } else if (!checked) {
        const elementIndex = copyForm[name].indexOf(value)
        copyForm[name].splice(elementIndex, 1)
      }
      setForm(copyForm)
    } else if (['swap_dealer', 'pba'].includes(name)) {
      copyForm[name] = checked
      setForm(copyForm)
    }
  }

  const submitForm = () => {
    if (validateForm()) {
      if (method === METHODS.POST) {
        sendFormData({ method, data: form, sendEndPoint: routes.entities })
      } else if (method === METHODS.PUT) {
        sendFormData({ method, data: { ...form, id: data.id }, sendEndPoint: routes.entities })
      }
    }
  }

  const disabled = () => {
    const formKeyValidate = ['entity_name', 'country', 'lei']
    return disabledSubmit(form, formKeyValidate)
  }

  if (checkSomeLoader(loaderList) && loaderFirstTime) {
    loaderFirstTime = false
    return <Loader />
  }

  const handelLeiBlur = () => {
    const lei = 'lei'
    const copyErrors = { ...errors }
    if (form[lei].length !== 20 && form[lei]) {
      copyErrors[lei] = ERRORS.length(lei, 20)
    }
    setErrors(copyErrors)
  }

  const handelLeiFocus = () => {
    const lei = 'lei'
    const copyErrors = { ...errors }
    copyErrors[lei] = ''
    setErrors(copyErrors)
  }

  const validateForm = () => {
    const errors = {}
    for (const key of Object.keys(form)) {
      if (['lei'].includes(key) && form[key].length !== 20 && form[key].length !== 0) {
        errors[key] = ERRORS.length(key, 20)
      }
    }
    if (Object.keys(errors).length) {
      setErrors(errors)
      return false
    }
    return true
  }

  return (
    <>
      <ModalContainer>
        <Grid container spacing={2}>
          <EntitiesLeftContainer item xs={5}>
            <Grid>
              <Label>Entity Name *</Label>
              <InputBaseStyle
                value={form.entity_name}
                name="entity_name"
                placeholder="Enter Name"
                onChange={handelFormChange}
              />
            </Grid>
            <Grid>
              {Array.isArray(countries.data) && (
                <>
                  <Label>Country *</Label>
                  <AutocompleteStyle
                    onChange={(event, element) => {
                      event.target.name = 'country'
                      element = element ?? ''
                      handelFormChange(event, element)
                    }}
                    size={'small'}
                    value={form.country}
                    getOptionLabel={option => option}
                    options={countries.data.map(({ name }) => name)}
                    renderInput={params => (
                      <TextFieldModalStyle
                        fullWidth
                        {...params}
                        placeholder="Select Country"
                        myerror={String(!!errors.country)}
                      />
                    )}
                    ListboxComponent={PaperAutoComplete}
                  />

                  {errors.country && <FormError>{errors.country}</FormError>}
                </>
              )}
            </Grid>
            <Grid>
              <Label>LEI</Label>
              <InputBaseStyle
                name="lei"
                autoComplete={'off'}
                value={form.lei}
                size="small"
                error={!!errors.lei}
                onChange={handelFormChange}
                onBlur={handelLeiBlur}
                onFocus={handelLeiFocus}
              />
              {errors.lei && <FormError>{errors.lei}</FormError>}
            </Grid>
            <Grid
              style={{
                display: 'flex',
                gap: '20px'
              }}
            >
              <FormControlLabelStyle
                onChange={handelFormChange}
                name="swap_dealer"
                control={
                  <CheckboxStyle
                    checked={form.swap_dealer}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                  />
                }
                label="Swap Dealer"
              />

              <FormControlLabelStyle
                name="pba"
                control={
                  <CheckboxStyle
                    checked={form.pba}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                  />
                }
                label="PBA"
                onChange={handelFormChange}
              />
            </Grid>
          </EntitiesLeftContainer>
          <Grid item xs={7}>
            <BookingRecaption
              contractDistribution={contractDistribution}
              handelEmail={handelEmail}
              handelFormChange={handelFormChange}
              form={form}
              searchEmail={searchEmail}
              submitNewContract={submitNewContract}
              style={{ height: '170px', maxHeight: '170px' }}
            ></BookingRecaption>
          </Grid>
        </Grid>
        <ModalBottom
          method={method}
          disabled={disabled()}
          submitForm={submitForm}
          title={Object.keys(data).length === 0 ? 'Create' : 'Save'}
          justifyContent="space-between"
          padding={{ paddingLeft: '0px', paddingRight: '0px' }}
        />
      </ModalContainer>
    </>
  )
}

export default Entities

Entities.propTypes = {
  data: PropTypes.object,
  method: PropTypes.string
}
