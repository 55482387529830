import React from 'react'
import PropTypes from 'prop-types'
import { TRADE_BLOTTER_STATUS, WARNING_MESSAGE } from '../../../../utils/constants'
import { NewText, WarningIconStyle, SpaceStyle, UnionIconStyle, TooltipStyle } from './Status.style'

let flag = true
const Status = ({ status, allocationStatus, stagingStatus, isDeal, sentToPb }) => {
  setTimeout(() => {
    flag = false
  }, 1000)

  if (allocationStatus === 'Unallocated') {
    return (
      <TooltipStyle title={WARNING_MESSAGE.DEAL_IS_UNALLOCATED} placement="top" color="#FF9747">
        <WarningIconStyle isdeal={String(true)} />
      </TooltipStyle>
    )
  }

  switch (status) {
    case TRADE_BLOTTER_STATUS.WARNING:
      return (
        <TooltipStyle title={WARNING_MESSAGE.MISMATCHED_QUANTITIES} placement="top" color="#FF9747">
          <WarningIconStyle isdeal={String(isDeal)} />
        </TooltipStyle>
      )
    case TRADE_BLOTTER_STATUS.NEW_END:
    case TRADE_BLOTTER_STATUS.NEW:
      if ((stagingStatus === 'Live' && !sentToPb && isDeal) || stagingStatus === 'Staged') {
        return (
          <TooltipStyle title={WARNING_MESSAGE.DEAL_IS_STAGED} placement="top" color="#FF9747">
            <WarningIconStyle isdeal={String(true)} />
          </TooltipStyle>
        )
      }
      if (stagingStatus === TRADE_BLOTTER_STATUS.PENDING) {
        return (
          <TooltipStyle title={WARNING_MESSAGE.DEAL_IS_PENDING} placement="top" color="#FF9747">
            <WarningIconStyle isdeal={String(true)} />
          </TooltipStyle>
        )
      }
      if (flag) {
        return <NewText isdeal={String(isDeal)}>NEW</NewText>
      } else {
        return <SpaceStyle isdeal={String(isDeal)} />
      }
    case TRADE_BLOTTER_STATUS.UNION:
      return (
        <TooltipStyle title={WARNING_MESSAGE.UNGROUPED_TRADE} placement="top" color="#FFDC51">
          <UnionIconStyle />
        </TooltipStyle>
      )
  }

  return <SpaceStyle isdeal={String(isDeal)} />
}

Status.propTypes = {
  allocationStatus: PropTypes.string,
  status: PropTypes.string,
  stagingStatus: PropTypes.string,
  isDeal: PropTypes.bool,
  sentToPb: PropTypes.number
}

export default Status
