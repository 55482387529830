import React from 'react'
import { Radio, Select, Switch, Typography, styled } from '@mui/material'
import { Button } from '@mui/material'
import { Grid } from '@mui/material'
import { InputLabel } from '@mui/material'
import { TextField, OutlinedInput } from '@mui/material'

export const Typography1Style = styled(Typography)(() => {
  return {
    color: 'var(--Primary-text, #FFF)',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Nunito',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal'
  }
})
export const Typography2Style = styled(Typography)(() => {
  return {
    color: 'var(--Primary-text, #FFF)',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Nunito',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal'
  }
})

export const Typography3Style = styled(Typography)(() => {
  return {
    color: 'var(--Light-grey-text, #BABEC7);',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Nunito',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal'
  }
})

export const StyleSelect = styled(Select)(() => {
  return {
    width: '100%',
    fontSize: '14px',
    lineHeight: 'normal',
    '& .MuiSelect-select': {
      padding: '0px !important'
    },
    '&.MuiInputBase-root': {
      fontSize: '14px',
      padding: '8px 11px !important',
      paddingRight: '32px !important',
      height: '100% !important',
      '::placeholder': {
        color: '#707079',
        opacity: 1
      }
    },
    '& .MuiSvgIcon-root': {
      fill: '#A4A4FF'
    }
  }
})

export const Typography4Style = styled(Typography)(() => {
  return {
    display: 'flex',
    flexWrap: 'wrap',
    lineHeight: '1',
    fontSize: '12px',
    padding: '6px 12px',
    borderRadius: '2px',
    fontWeight: 600,
    background: 'var(--Cancel-Button, #E6E6E6)'
  }
})

export const Typography5Style = styled(Typography)(() => {
  return {
    color: 'var(--Secondary-text, #707079)',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Nunito',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal'
  }
})

export const Typography6Style = styled(Typography)(() => {
  return {
    display: 'flex',
    width: '161px',
    height: '25px',
    padding: '8px 12px 8px 8px',
    alignItems: 'center',
    gap: '8px',
    flexShrink: 0,
    borderRadius: '4px',
    background: 'var(--Modal-BG, #242428)',
    color: 'var(--Secondary-text, #707079)',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Nunito',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal'
  }
})

export const Typography7Style = styled(Typography)(() => {
  return {
    color: 'var(--Light-grey-text, #BABEC7)',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Nunito',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    marginLeft: '8px'
  }
})

export const Typography8Style = styled(Typography)(() => {
  return {
    display: 'flex',
    width: '144px',
    height: '24px',
    padding: '8px 12px 8px 8px',
    alignItems: 'center',
    gap: '8px',
    flexShrink: 0,
    borderRadius: '4px',
    background: 'var(--Inputs-on-modal, #39393C)',
    color: 'var(--Secondary-text, #707079)',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Nunito',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal'
  }
})

export const Typography9Style = styled(Typography)(() => {
  return {
    color: 'var(--Primary-purple, #A4A4FF)',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Nunito',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal'
  }
})

export const Typography10Style = styled(Typography)(() => {
  return {
    color: ' var(--Primary-text, #FFF)',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Nunito',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    paddingTop: '8px',
    whiteSpace: 'nowrap'
  }
})

export const CloseButton = styled(Button)(() => {
  return {
    '&:hover': {
      background: 'var(--Cancel-Button, #E6E6E6)'
    },
    height: '32px',
    padding: '11px 24px',
    borderRadius: '4px',
    background: 'var(--Cancel-Button, #E6E6E6)',
    color: 'var(--Black, #000)',
    fontFamily: 'Nunito',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: 'normal',
    textAlign: 'center'
  }
})

export const SaveButton = styled(Button)(() => {
  return {
    '&:hover': {
      background: 'var(--Primary-purple, #A4A4FF)'
    },
    background: 'var(--Primary-purple, #A4A4FF)',
    height: '32px',
    padding: '11px 24px',
    borderRadius: '4px',
    color: 'var(--Black, #000)',
    fontFamily: 'Nunito',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: 'normal',
    textAlign: 'center'
  }
})

export const NavButton = styled(Button)(({ isActive }) => {
  return {
    color: 'var(--Light-grey-text, #BABEC7) ',
    height: 'auto',
    padding: '22px 32px',
    borderRadius: '4px',
    fontFamily: 'Nunito',
    fontSize: '12px',
    whiteSpace: 'nowrap',
    borderBottom: isActive ? '2px solid var(--Primary-purple, #A4A4FF)' : ''
  }
})

export const GridProduct = styled(Grid)(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '8px',
    height: '80px'
  }
})
export const StyleInputLabel = styled(InputLabel)(() => {
  return {
    color: 'var(--Secondary-text, #707079)',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Nunito',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    marginBottom: '8px'
  }
})
export const StyleTextField = styled(TextField)(({ error, theme }) => {
  let border = 'none !important'
  let marginBottom = '16px !important'
  if (String(error) === 'true') {
    border = `1px solid ${theme.palette.button.delete.primary}`
    marginBottom = '0px !important'
  }
  return {
    '&.MuiFormControl-root': {
      border
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important'
    },
    width: '100%',
    height: 'auto',
    borderRadius: '4px',
    background: 'var(--Inputs-on-modal, #39393C)',
    padding: '12px 8px',
    marginBottom
  }
})

export const LuceraContainer = styled(Grid)(() => ({
  maxHeight: '250px',
  height: '100%',
  display: 'block !important'
}))

export const IOSSwitch = styled(props => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 32,
  height: 16,
  padding: 0,
  marginRight: '15px',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)', // Adjust for the new width
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 12, // Adjust thumb size
    height: 12 // Adjust thumb size
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2, // Adjust track border radius
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}))

export const RadioStyle = styled(Radio)(({ theme }) => ({
  color: `${theme.palette.button.save.primary} !important`,
  padding: '0px',
  marginRight: '8px',
  '&:checked': {
    color: theme.palette.button.save.primary,
    backgroundColor: theme.palette.button.save.primary
  }
}))

export const TextFieldStyle = styled(TextField)(() => ({
  width: '100%',
  '& .MuiInputBase-root': {
    fontSize: '13px',
    height: 'auto !important',
    padding: '11px 8px'
  },
  '& .MuiInputBase-input': {
    fontSize: '13px',
    height: 'auto !important',
    padding: '0px',
    '::placeholder': {
      color: '#707079',
      opacity: 1
    }
  }
}))

export const OutlinedInputStyle = styled(OutlinedInput)(() => ({
  flexGrow: 1,
  height: 'auto !important',
  '& .MuiInputBase-root': {
    fontSize: '14px',
    height: 'auto !important',
    padding: '11px 8px'
  },
  '& .MuiInputBase-input': {
    fontSize: '14px',
    height: 'auto !important',
    padding: '0px',
    '::placeholder': {
      color: '#707079',
      opacity: 1
    }
  }
}))

export const ButtonProductPermission = styled(Button)(() => ({
  padding: '11px 24px',
  color: '#30F0D6',
  borderRadius: '3px',
  fontSize: '14px',
  border: '1px solid  #30F0D6',
  textTransform: 'capitalize',
  margin: '12px'
}))
