import { Button, Grid, styled, Typography } from '@mui/material'

export const ButtonAddNew = styled(Button)({
  borderRadius: '3px',
  background: '#39394E',
  color: '#A4A4FF',
  textAlign: 'center',
  leadingTrim: 'both',
  textEdge: 'cap',
  padding: '11px 24px',
  fontFamily: 'Nunito',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  textTransform: 'capitalize',
  '&:hover': {
    background: '#2F2F42'
  }
})

export const TraderTitle = styled(Typography)({
  color: 'var(--Purple-3, #7373BB)',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Nunito',
  fontSize: '18px',
  fontStyle: 'normal',
  marginBottom: '16px',
  fontWeight: '500',
  lineHeight: 'normal'
})

export const TraderItem = styled(Typography)({
  color: 'var(--Primary-text, #FFF)',
  fontSize: '14px',
  textTransform: 'capitalize'
})

export const EntitySideContainer = styled(Grid)({
  borderRight: '1px solid #39393C',
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '24px',
  paddingTop: '24px',
  paddingBottom: '24px',
  paddingRight: '40px',
  height: '100%',
  maxHeight: '100%'
})
