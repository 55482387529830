import React from 'react'
import PropTypes from 'prop-types'
import { EntitiesLinkContainer } from '../Entities.style'
import { Typography } from '@mui/material'

const EntityLinkNav = ({ entities }) => {
  return (
    <EntitiesLinkContainer>
      {entities.map((link, index) => (
        <React.Fragment key={index}>
          {index !== entities.length - 1 ? (
            <>
              {Object.values(link)[0]}
              {link.nextLink}
            </>
          ) : (
            <Typography>{link.text}</Typography>
          )}
        </React.Fragment>
      ))}
    </EntitiesLinkContainer>
  )
}

export default EntityLinkNav

EntityLinkNav.propTypes = {
  entities: PropTypes.array.isRequired
}
