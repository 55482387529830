import { Grid } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import {
  ButtonProductPermission,
  NavButton,
  StyleInputLabel,
  StyleTextField,
  Typography9Style
} from '../ClientMmDetails.style'
import { FormError } from '../../../../components/ModalApp/ModalApp.style'

const ProductPermission = ({
  buttonLabels,
  activeButton,
  userType,
  handleNavButtonClick,
  currencyPermissionData,
  handleTenorChange,
  handelPermissionClick,
  handelFocus,
  handelBlur,
  productPermissionError,
  limitM
}) => {
  return (
    <Grid container style={{ marginTop: '28px' }}>
      <Grid
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          padding: '12px 0px',
          borderTop: '1px solid #39393C'
        }}
      >
        <Grid item xs={10}>
          <Typography9Style style={{ color: '#707079' }}>Product Permissions</Typography9Style>
        </Grid>
        {limitM && (
          <Grid item style={{ display: 'flex', gap: '5px', justifyContent: 'end' }}>
            <Grid>
              <Typography9Style style={{ color: '#fff' }}>Total limit</Typography9Style>
            </Grid>
            <Grid>
              <Typography9Style>${limitM}M</Typography9Style>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid
        container
        item
        sx={{
          '& li:hover': {
            background: '#babec7'
          }
        }}
        style={{
          background: 'var(--Modal-BG, #242428)',
          overflow: 'auto',
          display: 'flex',
          height: '100%',
          borderBottom: '1px solid #39393C'
        }}
        className="container"
      >
        <Grid
          item
          xs={2}
          style={{
            maxWidth: 'none',
            flexGrow: 1
          }}
        >
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'start',
              borderBottom: '1px solid #39393C'
            }}
          >
            {buttonLabels.map((label, index) => {
              return (
                <Grid key={index}>
                  <NavButton
                    isActive={activeButton === label}
                    onClick={() => handleNavButtonClick(label, userType)}
                    style={{ borderRadius: '0px' }}
                  >
                    {label}
                  </NavButton>
                </Grid>
              )
            })}
          </Grid>
          <Grid
            item
            container
            xs={10}
            style={{
              maxWidth: 'none',
              padding: '24px',
              paddingRight: '44px',
              borderBottom: '1px solid #39393C'
            }}
          >
            <Grid item xs={12}>
              {currencyPermissionData?.map((tenor, index) => {
                return (
                  <React.Fragment key={index}>
                    <StyleInputLabel>{tenor.tenor_name}</StyleInputLabel>
                    <StyleTextField
                      style={{ marginBottom: '0px' }}
                      className="container"
                      onChange={e => handleTenorChange(e, tenor)}
                      onFocus={e => handelFocus(e, tenor.tenor_name)}
                      onBlur={e => {
                        handelBlur(e, tenor.tenor_name)
                      }}
                      error={String(tenor.tenor_name in productPermissionError)}
                      value={tenor.currencies}
                      sx={{
                        overflow: 'auto',
                        '.MuiInputBase-root.MuiOutlinedInput-root': {
                          fontSize: '12px',
                          padding: '0px',
                          height: 'auto !important'
                        },

                        '& li:hover': {
                          background: '#babec7'
                        }
                      }}
                      multiline
                    />
                    {tenor.tenor_name in productPermissionError && (
                      <FormError style={{ position: 'relative', marginBottom: '8px' }}>
                        {productPermissionError[tenor.tenor_name]}
                      </FormError>
                    )}
                  </React.Fragment>
                )
              })}
            </Grid>
          </Grid>
          <ButtonProductPermission onClick={handelPermissionClick}>
            Add Permissions
          </ButtonProductPermission>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ProductPermission

ProductPermission.propTypes = {
  buttonLabels: PropTypes.array,
  activeButton: PropTypes.string,
  userType: PropTypes.string,
  handleNavButtonClick: PropTypes.func,
  currencyPermissionData: PropTypes.array,
  handleTenorChange: PropTypes.func,
  handelPermissionClick: PropTypes.func,
  handelFocus: PropTypes.func,
  handelBlur: PropTypes.func,
  productPermissionError: PropTypes.object,
  limitM: PropTypes.any
}
