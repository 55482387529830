import { Grid } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import { TraderContainer } from '../../Entities.style'
import { ButtonAddNew, TraderItem, TraderTitle } from '../EntitesSide.style'
import { useDispatch } from 'react-redux'
import { setModal } from '../../../../redux/slices/Modal/modalSlice'
import { METHODS, MODAL_TITLE, MODAL_TYPE } from '../../../../utils/constants'

const Traders = ({ traders, entityName }) => {
  const dispatch = useDispatch()

  const createNewTrader = () => {
    dispatch(
      setModal({
        type: MODAL_TYPE.TRADER,
        title: MODAL_TITLE.NEW_TRADER,
        isOpen: true,
        method: METHODS.POST,
        data: { entity_name: entityName }
      })
    )
  }
  return (
    <>
      <Grid style={{ marginTop: '32px', display: 'flex', justifyContent: 'space-between' }}>
        <TraderTitle>Traders</TraderTitle>
        <ButtonAddNew onClick={createNewTrader}>Add New</ButtonAddNew>
      </Grid>
      <Grid>
        {!traders.status ? (
          <></>
        ) : traders.data.length > 0 ? (
          traders.data.map((key, index) => {
            return (
              <TraderContainer key={key} index={String(index)} length={String(traders.length)}>
                <TraderItem>{key.name}</TraderItem>
              </TraderContainer>
            )
          })
        ) : (
          <TraderItem> No traders currently set up for {entityName} </TraderItem>
        )}
      </Grid>
    </>
  )
}

export default React.memo(Traders)

Traders.propTypes = {
  traders: PropTypes.array,
  entityName: PropTypes.string
}
