import React from 'react'
import {
  LuceraContainer,
  StyleSelect,
  TextFieldStyle,
  Typography5Style
} from '../ClientMmDetails.style'
import PropTypes from 'prop-types'
import { DeleteIconStyle } from '../../../../components/styled/global.style'
import { Grid, IconButton, MenuItem } from '@mui/material'
import { AddIconStyle } from '../../../../components/ModalApp/children/Client/Client.style'

const LuceraDropValue = ({
  form,
  handelLuceraDropValue,
  handleDeleteLuceraValue,
  addLuceraRows
}) => {
  return (
    <Grid
      item
      xs={4}
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Grid className="luceraValue">
        <Typography5Style
          style={{
            marginBottom: '8px'
          }}
        >
          Lucera Drop Value
        </Typography5Style>
      </Grid>

      <Grid
        item
        style={{
          borderRadius: '4px',
          background: 'var(--Modal-BG, #242428)',
          padding: '12px',
          height: '100%',
          flexGrow: 1,
          flexDirection: 'column',
          position: 'relative'
        }}
      >
        <LuceraContainer className="container">
          {Array.isArray(form.lucera_drop_value) ? (
            form.lucera_drop_value.map(({ type, lucera }, index) => (
              <Grid
                container
                style={{
                  display: 'flex',
                  paddingBottom: '8px',
                  gap: '16px'
                }}
                key={index}
              >
                <Grid
                  item
                  lg={3}
                  md={4}
                  sm={5}
                  xs={5}
                  style={{
                    flex: 1,
                    lineHeight: 1
                  }}
                >
                  <StyleSelect
                    onChange={event =>
                      handelLuceraDropValue(index, 'lucera_type', event.target.value)
                    }
                    value={type}
                  >
                    <MenuItem
                      value="NY"
                      style={{ fontSize: '14px', paddingLeft: '8px', flexBasis: 'auto' }}
                    >
                      NY
                    </MenuItem>
                    <MenuItem
                      value="LDN"
                      style={{ fontSize: '14px', paddingLeft: '8px', flexBasis: 'auto' }}
                    >
                      LDN
                    </MenuItem>
                  </StyleSelect>
                </Grid>
                <Grid
                  item
                  lg={7}
                  md={6}
                  sm={6}
                  xs={6}
                  style={{
                    flex: 4
                    //minWidth: '200px'
                  }}
                >
                  <TextFieldStyle
                    fullWidth
                    onChange={event =>
                      handelLuceraDropValue(index, 'lucera_value', event.target.value)
                    }
                    value={lucera}
                    placeholder="Drop Value"
                  >
                    {lucera}
                  </TextFieldStyle>
                </Grid>
                <Grid item xs={1} style={{ flex: 1, minWidth: '24px' }}>
                  <DeleteIconStyle onClick={() => handleDeleteLuceraValue(type, lucera, index)} />
                </Grid>
              </Grid>
            ))
          ) : (
            <Typography5Style>No mapping values set</Typography5Style>
          )}
        </LuceraContainer>

        <Grid item xs={12}>
          <IconButton
            onClick={addLuceraRows}
            style={{
              padding: '0px',
              position: 'absolute',
              bottom: '10px',
              borderRadius: '4px',
              right: '10px'
            }}
          >
            <AddIconStyle />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LuceraDropValue

LuceraDropValue.propTypes = {
  form: PropTypes.object,
  addLuceraRows: PropTypes.func,
  handleDeleteLuceraValue: PropTypes.func,
  handelLuceraDropValue: PropTypes.func
}
