import React, { useEffect, useState } from 'react'
import StaticsToolBar from './components/StaticsToolBar/StaticsToolBar'
import { toolBarTabs } from './data'
import User from './components/User/User'
import { setModal } from '../../redux/slices/Modal/modalSlice'
import { useDispatch } from 'react-redux'
import { METHODS, MODAL_TITLE, MODAL_TYPE } from '../../utils/constants'
import Cuts from './components/Cuts/Cuts'
import CurrencyPairs from './components/CurrencyPairs/CurrencyPairs'
import FixingSources from './components/FixingSource/FixingSource'
import PrimeBroker from './components/PrimeBroker/PrimeBroker'

const Statics = () => {
  const tabInitial = localStorage.getItem('tab') ?? toolBarTabs[0]
  const [tab, setTab] = useState(tabInitial)
  const [params, setParams] = useState({ search: '' })
  const dispatch = useDispatch()

  useEffect(() => {
    if (params.search) {
      setParams({ search: '' })
    }
  }, [tab])

  const handelNewModal = () => {
    switch (tab) {
      case toolBarTabs[0]:
        dispatch(
          setModal({
            type: MODAL_TYPE.PRIME_BROKER,
            title: MODAL_TITLE.NEW_PRIME_BROKER,
            method: METHODS.POST,
            isOpen: true
          })
        )
        break
      case toolBarTabs[1]:
        dispatch(
          setModal({
            type: MODAL_TYPE.FIXING_SOURCE,
            title: MODAL_TITLE.NEW_FIXING_SOURCE,
            method: METHODS.POST,
            isOpen: true
          })
        )
        break
      case toolBarTabs[2]:
        dispatch(
          setModal({
            type: MODAL_TYPE.CUTS,
            title: MODAL_TITLE.NEW_CUT,
            method: METHODS.POST,
            isOpen: true
          })
        )
        break
      case toolBarTabs[3]:
        dispatch(
          setModal({
            type: MODAL_TYPE.CURRENCY_PAIRS,
            title: MODAL_TITLE.NEW_CURRENCY_PAIR,
            method: METHODS.POST,
            isOpen: true
          })
        )
        break
      case toolBarTabs[4]:
        dispatch(
          setModal({
            type: MODAL_TYPE.USERS,
            title: MODAL_TITLE.NEW_USER,
            method: METHODS.POST,
            isOpen: true
          })
        )
        break
      default:
        break
    }
  }

  const handelSearch = event => {
    setParams({ search: event.target.value })
  }

  return (
    <>
      <StaticsToolBar
        tab={tab}
        setTab={setTab}
        searchValue={params.search ?? ''}
        handelSearch={handelSearch}
        handelNewModal={handelNewModal}
      />
      {tab === toolBarTabs[0] && <PrimeBroker params={params} />}
      {tab === toolBarTabs[1] && <FixingSources params={params} />}
      {tab === toolBarTabs[2] && <Cuts params={params} />}
      {tab === toolBarTabs[3] && <CurrencyPairs params={params} />}
      {tab === toolBarTabs[4] && <User params={params} />}
    </>
  )
}

export default Statics
