import { Box, FormControl, IconButton, InputAdornment, List, OutlinedInput } from '@mui/material'
import PropTypes from 'prop-types'
import React, { lazy, Suspense } from 'react'
import { AddIconStyle } from '../ModalApp/ModalApp.style'
import { Label } from '../styled/global.style'
import { BookingRecaptionSearchTitle } from './BookingRecaption.style'

const ContractDistribution = lazy(() => import('./components/ContractDistribution'))

const BookingRecaption = ({
  contractDistribution,
  handelEmail,
  handelFormChange,
  form,
  searchEmail,
  submitNewContract,
  style = { maxHeight: '220px', height: '220px' },
  backgroundSearchBarContainer = '#39393C',
  backgroundSearchBar = '#242428',
  pageArea
}) => {
  const renderContractDistributions = emails => {
    return emails.map(email => (
      <ContractDistribution
        key={email} // Assuming `email` is unique
        email={email}
        handelFormChange={handelFormChange}
        form={form}
        pageArea={pageArea}
      />
    ))
  }

  const getEmailsToDisplay = () => {
    if (searchEmail.search !== '') {
      return contractDistribution.data.map(({ email }) => email)
    }
    if (form.emails.length > 0) {
      return form.emails
    }
    return null
  }
  const emailsToDisplay = getEmailsToDisplay()

  return (
    <>
      <Label>Default Booking Recipients</Label>

      {Array.isArray(contractDistribution.data) && (
        <List
          style={{
            ...style,
            borderTopRightRadius: '4px',
            borderTopLeftRadius: '4px',
            padding: '10px'
          }}
          className="container"
        >
          {emailsToDisplay ? (
            <Suspense>{renderContractDistributions(emailsToDisplay)}</Suspense>
          ) : (
            <BookingRecaptionSearchTitle>
              Please search for an existing email address, or add a new one
            </BookingRecaptionSearchTitle>
          )}
        </List>
      )}

      <Box
        style={{
          backgroundColor: backgroundSearchBarContainer,
          width: '100%',
          display: 'flex'
        }}
      >
        <FormControl
          variant="outlined"
          style={{
            padding: '8px',
            flex: 1,
            boxShadow: 'rgba(0, 0, 0, 0.25) 0px -4px 4px 0px'
          }}
        >
          <OutlinedInput
            style={{ backgroundColor: backgroundSearchBar, color: 'white', padding: '11px 8px' }}
            type="email"
            value={searchEmail.search}
            onChange={handelEmail}
            placeholder="example@gmail.com"
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={submitNewContract} edge="end">
                  <AddIconStyle />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>
    </>
  )
}

export default BookingRecaption

BookingRecaption.propTypes = {
  contractDistribution: PropTypes.object,
  handelEmail: PropTypes.func,
  handelFormChange: PropTypes.func,
  searchEmail: PropTypes.object,
  submitNewContract: PropTypes.func,
  form: PropTypes.object,
  style: PropTypes.object,
  backgroundSearchBar: PropTypes.string,
  backgroundSearchBarContainer: PropTypes.string,
  pageArea: PropTypes.string
}
