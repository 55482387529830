import React, { useState } from 'react'
import { METHODS, MODAL_TITLE, MODAL_TYPE, routes } from '../../utils/constants.js'
import useAxios from '../../hooks/useAxios.js'
import Table from '../../components/Table/Table.js'
import Loader from '../../components/Loader/Loader.js'
import { Link } from 'react-router-dom'
import { Grid } from '@mui/material'
import { ButtonAddNew, EditIconStyle } from '../../components/styled/global.style.js'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { handelNotifications } from '../../redux/slices/Notification/notificationSlice.js'
import { setModal } from '../../redux/slices/Modal/modalSlice.js'
import SearchTextField from '../../components/SearchTextField/SearchTextField.js'
import { EntitiesTool } from './Entities.style.js'

const Entities = () => {
  const token = useSelector(state => state.auth.token)
  const [search, setSearch] = useState({ search: '' })

  const request = {
    endPoint: routes.entities,
    params: search,
    modalType: MODAL_TYPE.ENTITIES
  }

  const { response, loading } = useAxios(request)
  const dispatch = useDispatch()

  const editEntity = async id => {
    const url = process.env.REACT_APP_SERVER_URL.concat(routes.entities, '/', id)
    try {
      const result = await axios.get(url, { headers: { Authorization: token } })
      dispatch(
        setModal({
          type: MODAL_TYPE.ENTITIES,
          title: MODAL_TITLE.EDIT_ENTITY,
          data: result.data,
          method: METHODS.PUT,
          isOpen: true
        })
      )
    } catch (error) {
      dispatch(
        handelNotifications({
          id: Date.now(),
          type: 'error',
          message: 'Sorry Something Went Wrong'
        })
      )
    }
  }

  const createNewEntity = () => {
    dispatch(
      setModal({
        type: MODAL_TYPE.ENTITIES,
        title: MODAL_TITLE.NEW_ENTITY,
        method: METHODS.POST,
        isOpen: true
      })
    )
  }

  const EditEntity = prop => {
    const { accountId } = prop.data
    return <EditIconStyle onClick={() => editEntity(accountId)} />
  }

  const entityLinkRenderer = params => {
    const { accountId: entityId, entityName } = params.data
    return (
      <Link to={`/entities/${entityId}`} style={{ textDecoration: 'none', color: 'white' }}>
        {entityName}
      </Link>
    )
  }

  const handelGlobalSearchFilter = event => {
    setSearch({ search: event.target.value })
  }

  const columns = [
    { headerName: 'Account ID', field: 'accountId', flex: 1 },
    { headerName: 'Entity Name', field: 'entityName', flex: 1, cellRenderer: entityLinkRenderer },
    { headerName: 'Country', field: 'country', flex: 1 },
    { headerName: 'LEI', field: 'lei', flex: 1 },
    {
      flex: 1,
      resizable: false,
      suppressMovable: true,
      cellClass: 'suppress-movable-col'
    },
    { headerName: 'Edit', cellRenderer: EditEntity }
  ]

  if (loading === true) {
    return <Loader />
  }
  return (
    <Grid
      container
      style={{
        height: '100%'
      }}
    >
      <Grid
        item
        xs={12}
        style={{
          height: '100%'
        }}
      >
        <EntitiesTool>
          <SearchTextField handleChange={handelGlobalSearchFilter} state={search.search} />
          <ButtonAddNew onClick={createNewEntity}>Add New</ButtonAddNew>
        </EntitiesTool>

        <Grid>
          {response.status === 200 ? (
            <Table rows={response.data} columns={columns} />
          ) : (
            <Table rows={[]} columns={columns} />
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Entities
