const useLuceraDropValue = ({ form, setForm }) => {
  const addLuceraRows = () => {
    const copyForm = JSON.parse(JSON.stringify(form))
    if (!copyForm.lucera_drop_value.every(lucera => lucera.type && lucera.lucera)) return
    copyForm.lucera_drop_value.push({ type: '', lucera: '', status: true })
    setForm(copyForm)
  }

  const handelLuceraDropValue = (index, name, value) => {
    const copyForm = JSON.parse(JSON.stringify(form))

    if (name === 'lucera_type') {
      copyForm.lucera_drop_value[index].type = value
    } else if (name === 'lucera_value') {
      copyForm.lucera_drop_value[index].lucera = value
    }
    setForm(copyForm)
  }

  const handleDeleteLuceraValue = (type, lucera, index) => {
    setForm(prevForm => ({
      ...prevForm,
      lucera_drop_value: prevForm.lucera_drop_value.filter((key, i) => i !== index)
    }))
  }

  return { addLuceraRows, handelLuceraDropValue,handleDeleteLuceraValue }
}

export default useLuceraDropValue
