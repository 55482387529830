import axios from 'axios'
import { METHODS, MODAL_TITLE, MODAL_TYPE, routes } from '../../../../utils/constants'
import { setModal } from '../../../../redux/slices/Modal/modalSlice'
import { useDispatch } from 'react-redux'

const useProductPermission = ({
  setActiveButton,
  clientMmId,
  token,
  setCurrencyPermissionData,
  seCurrencyPermissionChange,
  currencyPermissionData,
  seCurrencyChange,
  setProductPermissionError,
  userType
}) => {
  const dispatch = useDispatch()

  const handleNavButtonClick = async (buttonName, userType) => {
    let type
    setActiveButton(buttonName)
    setProductPermissionError({})
    if (buttonName === 'Spot') {
      type = 1
    } else if (buttonName === 'Forwards') {
      type = 2
    } else if (buttonName === 'Vanilla Options') {
      type = 4
    } else if (buttonName === 'Exotic Options') {
      type = 5
    } else if (buttonName === 'Swap') {
      type = 3
    }
    let url
    if (userType === 'client') {
      url = process.env.REACT_APP_SERVER_URL.concat(routes.client, '/', clientMmId, '/', type)
    } else {
      url = process.env.REACT_APP_SERVER_URL.concat(routes.marketMaker, '/', clientMmId, '/', type)
    }
    try {
      const response = await axios.get(url, { headers: { Authorization: token } })
      setCurrencyPermissionData(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleTenorChange = (e, tenor) => {
    seCurrencyPermissionChange(tenor)
    const currencyArray = e.target.value.split(',').map(curr => curr.trim().toUpperCase())
    const updatedData = currencyPermissionData.map(item => {
      if (item.tenor_name === tenor.tenor_name) {
        return {
          ...item,
          currencies: currencyArray
        }
      }
      return item
    })
    seCurrencyChange(currencyArray)
    setCurrencyPermissionData(updatedData)
  }

  const handelPermissionClick = () => {
    dispatch(
      setModal({
        type: MODAL_TYPE.PERMISSION,
        title: MODAL_TITLE.ADD_PERMISSIONS,
        isOpen: true,
        method: METHODS.POST,
        data: { clientMmId, userType }
      })
    )
  }

  return { handleNavButtonClick, handleTenorChange, handelPermissionClick }
}

export default useProductPermission
